import { createContext, ReactNode, useContext, useState } from "react"

interface Props {
  children: ReactNode
}

interface Alert {
  type: 'info' | 'success' | 'warn' | 'error'
  title: string
  message: string
}

interface FullAlert extends Alert {
  show: boolean
  id: string
}

interface AlertContextType {
  alerts: Array<FullAlert>
  addAlert: (value: Alert) => void
  hideAlert: (value: string) => void
}

const AlertContext = createContext<AlertContextType>({alerts:[], addAlert(){}, hideAlert(){}})

export const useAlert = () => {
  return useContext(AlertContext)
}

export const AlertContextProvider = ({children}: Props) => {

  const [alerts, setAlerts] = useState<Array<FullAlert>>([])

  const addAlert = (alert:Alert) => {
    let timeout = 0;
    switch (alert.type) {
      case "success":
        timeout = 4000; // Hide alert after 5 seconds
        break;
      case "error":
        timeout = 12000; // Hide alert after 12 seconds
        break;
      case "info":
        timeout = 10000; // Hide alert after 10 seconds
        break;
      case "warn":
        timeout = 8000; // Hide alert after 10 seconds
        break;
    }
    const newAlert = alert as FullAlert
    newAlert.id = uidGenerator()
    newAlert.show = false
    setAlerts(alerts => [...alerts, newAlert])
    setTimeout(() => showAlert(newAlert.id), 100)
    setTimeout(() => hideAlert(newAlert.id), timeout)
  }

  const showAlert = (id: string) => {
    setAlerts(alerts => {
      const i = alerts.findIndex(al => al.id === id)
      if(i<0) return alerts
      return [
        ...alerts.slice(0,i),
        {
          ...alerts[i],
          show: true,
        },
        ...alerts.slice(i+1)
      ]
    })
  }

  const hideAlert = (id: string) => {
    setAlerts(alerts => {
      const i = alerts.findIndex(al => al.id === id)
      if(i<0) return alerts
      return [
        ...alerts.slice(0,i),
        {
          ...alerts[i],
          show: false,
        },
        ...alerts.slice(i+1)
      ]
    })
    setTimeout(() => removeAlert(id), 300)
  }

  const removeAlert = (id: string) => {
    setAlerts(alerts => {
      return alerts.filter(alert => alert.id !== id)
    })
  }

  const uidGenerator = () => {
    var S4 = () => (((1+Math.random())*0x10000)|0).toString(16).substring(1)
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4())
  }

  return <AlertContext.Provider value={{alerts, addAlert, hideAlert}}>{children}</AlertContext.Provider>
}