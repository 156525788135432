import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FaTimes } from 'react-icons/fa'
import { MdColorLens } from 'react-icons/md'
import { colorsMap } from '../../services/utils'

interface Props {
  name: string
  ariaLable: string
  value: string
  onChange: (value : React.ChangeEvent<HTMLInputElement>) => void
  label?: string
}

function ColorPicker({name, ariaLable, value, onChange, label}: Props) {
  const { t } = useTranslation(['global'])
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <label className="grid grid-cols-1 gap-1">
        {label && <b>{label} </b>}
        <input 
          type="button" 
          name={name}
          className={`p-1.5 w-[38px] h-[38px] cursor-pointer border border-solid border-transparent rounded focus:outline-none focus:ring-4 focus:ring-corporate-300 focus:border-corporate-400 hover:opacity-70 ${value ? colorsMap.get(value)?.bg || 'bg-[#0693E3]' : 'bg-[#0693E3]'}`}
          aria-label={ariaLable}
          value=''
          onClick={() => setShowModal(true)}
          />
      </label>
      {showModal && (
        <>
          <div onClick={() => setShowModal(false)} className="fixed z-10 inset-0 bg-gray-600 bg-opacity-90"></div>
          <div className="fixed z-10 right-auto left-auto top-14 bg-white dark:bg-shark-900 rounded">
            <div className="bg-corporate-500 text-white dark:text-shark-950 py-2 px-4 flex justify-between rounded-t">
              <div className="flex text-xl">
                <MdColorLens size={24} className='mt-[2px] mr-3'/>
                {t('colors')}
              </div>
              <button className="focus:outline-none focus:ring-4 focus:ring-corporate-300 focus:bg-corporate-600 focus:text-corporate-200 hover:bg-corporate-600 hover:text-corporate-200 rounded px-2" onClick={() => setShowModal(false)} aria-label='close modal'><FaTimes /></button>
            </div>
            <div className="grid grid-cols-5 gap-3 p-4">
              {[...colorsMap.keys()].map(color => {
                return (
                  <input 
                    key={color}
                    type="button" 
                    name={color}
                    className={`p-1 w-[34px] cursor-pointer border border-solid border-transparent rounded focus:outline-none focus:ring-4 focus:ring-corporate-300 focus:border-corporate-400 hover:opacity-70 ${colorsMap.get(color)?.bg}`}
                    aria-label={color}
                    value=''
                    onClick={() => {setShowModal(false); onChange({target:{name:name, value:color}} as React.ChangeEvent<HTMLInputElement>)} }
                  />
                )
              })}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ColorPicker