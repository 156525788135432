
interface Props {
  options:  Array<{ 
    label: string, 
    value: string
  }>
  defaultValue?:string
  handler: (value: string) => void;
}

function Select({options, defaultValue, handler}: Props) {
  return (
    <select 
      className='select-wrapper dark:select-wrapper-dark py-1.5 pl-2 pr-7 w-full border border-solid border-gray-300 rounded focus:outline-none focus:ring-4 focus:ring-corporate-300 focus:border-corporate-400 bg-white dark:bg-shark-950'
      defaultValue={defaultValue} 
      onChange={(e) => handler(e.currentTarget.value)}
    >
      {options.map(({value, label}) => (
        <option key={label} value={value}>{label}</option>
      ))}
    </select>
  )
}

export default Select