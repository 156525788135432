import { useState } from "react"
import TableFilters from "./TableFilters"
import { MdFilterAlt } from "react-icons/md"
import { useTranslation } from "react-i18next"


interface Props {
  searchText?: string
  setSearchText?: (value:string) => void
  filters?: Array<{
    field: string
    options: Array<{
      label:string
      value:string
    }>
  }>
  selectedFilters?: Array<{
    field:string
    value:string
  }>
  setSelectedFilters?: (value:Array<SelectedFilter>) => void
}

interface SelectedFilter {
  field:string
  value:string
}

function TableSearch({setSearchText, filters, selectedFilters, setSelectedFilters}: Props) {

  const { t } = useTranslation(['global'])
  const [showFilters, setShowFilters] = useState(false)

  return (
    <div className="flex items-center absolute left-4 top-2 sm:left-[72px]">
      {setSearchText && <input 
        type="text" 
        name="search"
        placeholder={t('search') || 'Search'}
        className="focus:outline-none focus:ring-4 focus:ring-corporate-300 px-1.5 py-[5px] border border-solid border-gray-300 rounded mr-4 focus:border-corporate-400 dark:bg-shark-950" 
        aria-label={t('search') || 'Search'}
        onChange={(e) => setSearchText(e.target.value.normalize("NFD").replace(/\p{Diacritic}/gu, ""))} 
        />}
      {filters && <button className="text-white dark:text-shark-950 border border-corporate-500 bg-corporate-500 focus:outline-none focus:ring-4 focus:ring-corporate-300 focus:bg-corporate-600 focus:border-corporate-600 focus:text-corporate-200 hover:bg-corporate-600 hover:border-corporate-600 hover:text-corporate-200 rounded p-2" onClick={() => setShowFilters(true)}><MdFilterAlt /></button>}
      {showFilters && setSelectedFilters && <TableFilters setShowFilters={setShowFilters} filters={filters || []} selectedFilters={selectedFilters || []} setSelectedFilters={setSelectedFilters} />}
    </div>
  )
}

export default TableSearch