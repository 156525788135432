import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from "react-router-dom";
import ApproveModal from '../components/global/ApproveModal'
import Table from '../components/global/Table'
import RoomForm from '../components/RoomForm'
import { useAlert } from '../context/AlertContext'
import { useStoredFirestoreCollection } from '../hooks/useFirestoreCollection'
import { filterTextArray, sortArray } from '../services/utils'
import { Days, Room } from '../types'
import { useAppSelector } from '../store/store';

interface UseFirestoreCollectionType {
  addItem: (item: Room) => Promise<void>
  updateItem: (item: Room) => Promise<void>
  deleteItem: (item: Room) => Promise<void>
}
interface Sort {
  field: "name" | "number" | "active"
  order: number
}

function Rooms() {

  const { t } = useTranslation(['room', 'global'])
  let navigate = useNavigate()
  const { roomId } = useParams()

  const { addAlert } = useAlert()
  const { rooms:items } = useAppSelector(state => state.rooms)
  const { addItem, updateItem, deleteItem } = useStoredFirestoreCollection("rooms") as UseFirestoreCollectionType
  const [sort, setSort] = useState<Sort>({field:'number', order:1})
  const [searchText, setSearchText] = useState<string>('')
  const [deleteRoom, setDeleteRoom] = useState<Room|undefined>(undefined)
  const [currentRoom, setCurrentRoom] = useState<Room|undefined>(undefined)

  const columns = [
    {label:t('name'), value:'name', sortable:true, highlight:true},
    {label:t('number'), value:'number', sortable:true, class:'w-[110px]'},
    {label:t('active'), value:'active', type:'boolean', sortable:true, class:'w-[100px]', hideMobile: true},
  ]
  
  const newDays:Days = {
    day0: [],
    day1: [],
    day2: [],
    day3: [],
    day4: [],
    day5: [],
    day6: []
  }

  const newRoom:Room = {
    name: '',
    number: 0,
    active: true,
    color: '7BDCB5',
    days: newDays
  }

  const upsertHandler = async (room:Room) => {
    try {
      if (room.id) {
        await updateItem(room)
        addAlert({type:"success", title:t('room_updated'), message:room.name || ''})
      } else {
        await addItem(room)
        addAlert({type:"success", title:t('room_created'), message:room.name || ''})
      }
      navigate(-1)
    } catch (error) {
      console.error(error)
      addAlert({type:"error", title:t('genericFirebaseError',{ns:'global'}), message:''})
    }
  }

  const deleteHandler = async (value:boolean) => {
    try {
      if(value && deleteRoom) {
        await deleteItem(deleteRoom)
        addAlert({type:"success", title:t('room_deleted'), message:deleteRoom?.name || ''})
      }
      setDeleteRoom(undefined)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if(items.length < 1 && roomId && roomId !== 'new') return
    if(roomId) setCurrentRoom(items.filter(i => i.id === roomId)[0] || newRoom)
    else if(currentRoom) setCurrentRoom(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[roomId, items])

  return (
    <>
      {deleteRoom && <ApproveModal title={t('delete_room')} question={t('delete_room_question')} item={deleteRoom.name} approve={deleteHandler}/>}
      {currentRoom ?
        <RoomForm 
          room={currentRoom} 
          submit={upsertHandler}
          cancel={() => navigate(-1)}
        /> : 
        <Table 
          columns={columns} 
          items={sortArray(filterTextArray(items, searchText, ['name']), sort.field, sort.order)}
          searchText={searchText}
          setSearchText={setSearchText}
          sort={sort} 
          setSort={setSort as any} 
          editable={true}
          editItem={(item) => navigate(item.id)}
          newItem={() => navigate('new')}
          deleteItem={setDeleteRoom}
        />
      }
    </>
  )
}

export default Rooms