import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Table from "../components/global/Table";
import { useFirestoreCollection, Filter } from "../hooks/useFirestoreCollection";
import { Booking } from "../types";
import { useNavigate } from "react-router-dom";
import { getDateTime } from "../services/utils";
import ApproveModal from "../components/global/ApproveModal";
import { useAlert } from "../context/AlertContext";
import { useAppSelector } from "../store/store";
import BookingForm from "../components/BookingForm";

interface UseFirestoreCollectionType {
  items: Array<Booking>
  addItem: (item: Booking) => Promise<void>
  updateItem: (item: Booking) => Promise<void>
  deleteItem: (item: Booking) => Promise<void>
  sort: {
    field: string
    order: number
  }
  setSort: (value: {field:string, order:number}) => void
  selectedFilters: Array<Filter>
  setSelectedFilters: (value: Array<Filter>) => void
  setLimitItems: (value: any) => void
}

function Bookings() {
  
  const { t, i18n } = useTranslation(['booking', 'global'])
  let navigate = useNavigate()
  const { bookingId } = useParams()
  const limitItems = 40

  const { addAlert } = useAlert()
  const { rooms } = useAppSelector(state => state.rooms)
  const [filters, setFilters] = useState<any>([])
  const {items, addItem, updateItem, deleteItem, sort, setSort, selectedFilters, setSelectedFilters, setLimitItems} = useFirestoreCollection("bookings", {field:'startDate', order:-1}, limitItems) as UseFirestoreCollectionType
  const [deleteBooking, setDeleteBooking] = useState<Booking|undefined>(undefined)
  const [currentBooking, setCurrentBooking] = useState<Booking|undefined>(undefined)

  const scrollBottom = (value:boolean) => {
    if(value) setLimitItems((prevState:number) => prevState + limitItems)
  }
  
  const columns = [
    {label:t('client',{ns:'global'}), value:'clientName'},
    {label:t('room',{ns:'global'}), value:'roomName', hideMobile: true},
    {label:t('status'), value:'status', type:'bookingStatus', class:'w-[80px]'},
    {label:t('date',{ns:'global'}), value:'startDate', type:'dateBooking', sortable:true, class:'w-[240px]', hideMobile: true}
  ]

  const date = new Date().setHours(10,0,0,0)

  const newBooking:Booking = {
    roomId: '',
    roomName: '',
    clientId: '',
    clientName: '',
    color: '7BDCB5',
    startDate: new Date(date).toISOString(),
    duration: 60,
    status: "unpaid",
    price: 0,
    totalPrice: 0,
    remarks: '',
    tags: []
  }

  const upsertHandler = async (booking:Booking) => {
    try {
      if (booking.id) {
        await updateItem(booking)
        addAlert({type:"success", title:t('booking_updated'), message:booking.clientName+' ('+booking.roomName+') ' || ''})
      } else {
        await addItem(booking)
        addAlert({type:"success", title:t('booking_created'), message:booking.clientName+' ('+booking.roomName+') ' || ''})
      }
      navigate(-1)
    } catch (error) {
      console.error(error)
      addAlert({type:"error", title:t('genericFirebaseError',{ns:'global'}), message:''})
    }
  }

  const deleteHandler = async (value:boolean) => {
    try {
      if(value && deleteBooking) {
        await deleteItem(deleteBooking)
        addAlert({type:"success", title:t('booking_deleted'), message:deleteBooking?.clientName+' ('+deleteBooking.roomName+') ' || ''})
      }
      setDeleteBooking(undefined)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const filters = [
      {field: 'status', options: [
        {label:t('allStatus'), value:'allItems'},
        {label:t('unpaid'), value:'unpaid'},
        {label:t('paid'), value:'paid'},
        {label:t('bonus'), value:'bonus'},
      ]},
      {field: 'roomId', options: [
        {label:t('allRooms'), value:'allItems'}
      ]}
    ]
    rooms.forEach(room => filters[1].options.push({label:room.name, value:room.id || ''}))
    setFilters(filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if(items.length < 1 && bookingId && bookingId !== 'new') return
    if(bookingId) setCurrentBooking(items.filter(i => i.id === bookingId)[0] || newBooking) // GET BOOKING FROM FIREBASE!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    else if(currentBooking) setCurrentBooking(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[bookingId, items])
  
  return (
    <>
      {deleteBooking && <ApproveModal title={t('delete_booking')} question={t('delete_booking_question')} item={deleteBooking.clientName+' ('+deleteBooking.roomName+') '+getDateTime(deleteBooking.startDate, i18n.resolvedLanguage)} approve={deleteHandler}/>}
      {currentBooking ?
        <BookingForm 
          booking={currentBooking} 
          submit={upsertHandler}
          cancel={() => navigate(-1)}
          fullPage={true}
        /> : 
        <Table 
          columns={columns} 
          items={items} 
          sort={sort} 
          setSort={setSort} 
          filters={filters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          editable={true}
          editItem={(item) => navigate(item.id)}
          newItem={() => navigate('new')}
          deleteItem={setDeleteBooking}
          setScrollBottom={scrollBottom}
        />
      }
    </>
  )
}

export default Bookings