import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../store/store'
import { colorsMap, getDayNameShort, getDaysArray, getTimeFromDate, getTimeFromInputString, iconsMap } from '../services/utils'
import { useFirestoreCollection, Filter } from "../hooks/useFirestoreCollection"
import { getStringTimeForInputFromTime } from '../services/utils'
import { Booking, Days } from '../types'
import { useAlert } from '../context/AlertContext'
import BookingForm from '../components/BookingForm'
import DateNavigator from '../components/global/DateNavigator'
import MobileSliderX from '../components/global/MobileSliderX'
import { IconType } from 'react-icons'
import ButtonSmall from '../components/global/ButtonSmall'

interface Slot {
  hour: number
  stringHour: string
  rooms: Array<RoomStatus>
}

interface RoomStatus {
  roomId: string
  roomName: string
  booking?: Booking
  status: "unavailable" | "available"
  number: number
}

interface UseFirestoreCollectionType {
  items: Array<Booking>
  addItem: (item: Booking) => Promise<void>
  updateItem: (item: Booking) => Promise<void>
  deleteItem: (item: Booking) => Promise<void>
  selectedFilters: Array<Filter>
  setSelectedFilters: (value: Array<Filter>) => void
}

function Calendar() {

  var today = new Date()
  today.setHours(0,0,0,0)
  var thresholdDate = new Date(today)
  thresholdDate.setDate(today.getDate() - 2) //to avoid getting all bookings if search an old date
  const days:Array<Date> = getDaysArray(today, 30)

  const { t, i18n } = useTranslation(['booking', 'global'])
  const { addAlert } = useAlert()
  const { rooms } = useAppSelector(state => state.rooms)
  const [selectedDate, setSelectedDate] = useState<Date>(today)
  const [slotsState, setSlotsState] = useState<Array<Slot>>([])
  const [currentBooking, setCurrentBooking] = useState<Booking|undefined>(undefined)
  const {items, addItem, updateItem, deleteItem, selectedFilters, setSelectedFilters } = useFirestoreCollection("bookings", undefined, undefined, [{field:'startDate', operator:'>=', value:today.toISOString()}]) as UseFirestoreCollectionType

  const newBooking = (roomId:string, roomName:string, hour:string) => {
    const d = new Date(selectedDate)
    d.setHours(parseInt(hour.split(':')[0]))
    d.setMinutes(parseInt(hour.split(':')[1]))
    const booking: Booking = {
      roomId: roomId,
      roomName: roomName,
      clientId: '',
      clientName: '',
      color: '7BDCB5',
      startDate: d.toISOString(),
      duration: 60,
      status: "unpaid",
      price: 0,
      totalPrice: 0,
      remarks: '',
      tags: []
    }
    setCurrentBooking(booking)
  }


  const upsertHandler = async (booking:Booking) => {
    try {
      if (booking.id) {
        await updateItem(booking)
        addAlert({type:"success", title:t('booking_updated'), message:booking.clientName+' ('+booking.roomName+') ' || ''})
      } else {
        await addItem(booking)
        addAlert({type:"success", title:t('booking_created'), message:booking.clientName+' ('+booking.roomName+') ' || ''})
      }
    } catch (error) {
      console.error(error)
      addAlert({type:"error", title:t('genericFirebaseError',{ns:'global'}), message:''})
    }
  }

  const setSlots = () => {
    const slots: Array<Slot> = []
    let startTime = 23 * 60
    let endTime = 0
    const day = selectedDate.getDay() === 0 ? 'day6' : 'day' + (selectedDate.getDay() - 1).toString()
    //Get startTime and endTime
    const activeRooms = rooms.filter(r => r.active)
    activeRooms.forEach(room => {
      room.days[day as keyof Days].forEach(price => {
        const start = getTimeFromInputString(price.startTime)
        if(start < startTime) startTime = start
        const end = getTimeFromInputString(price.endTime)
        if(end > endTime) endTime = end
      })
    })
    //Set slots
    activeRooms.forEach(room => {
      room.days[day as keyof Days].forEach(price => {
        if(price.hours === 1) {
          let time = startTime
          const roomStartTime = getTimeFromInputString(price.startTime)
          const roomEndTime = getTimeFromInputString(price.endTime)
          do {
            const roomStatus: RoomStatus = {
              roomId: room.id || '', 
              roomName: room.name, 
              status: time >= roomStartTime && time < roomEndTime ? "available" : "unavailable", 
              number: room.number
            }
            const index = slots.map(s => s.hour).indexOf(time)
            if(index < 0) {
              slots.push({
                hour: time,
                stringHour: getStringTimeForInputFromTime(time),
                rooms: [roomStatus]
              })
            } else {
              const roomIndex = slots[index].rooms.map(d => d.roomId).indexOf(room.id || '')
              if(roomIndex < 0) slots[index].rooms.push(roomStatus)
              else if(slots[index].rooms[roomIndex].status === 'unavailable' && roomStatus.status === 'available') slots[index].rooms[roomIndex].status = 'available'
            }
            time += 30
          }
          while (time < endTime)
        }
      })
    })
    //set bookings
    let today5am = new Date(selectedDate)
    today5am.setHours(5,0,0,0) // from 5:00 AM
    let tomorrow5am = new Date(today5am)
    tomorrow5am.setDate(tomorrow5am.getDate() + 1) // to next day 5:00 AM
    const bookings = items.filter(b => b.startDate >= today5am.toISOString() && b.startDate < tomorrow5am.toISOString())
    bookings.forEach(booking => {
      const date = new Date(booking.startDate)
      const startTime = getTimeFromDate(date)
      let duration = booking.duration
      do {
        duration -= 30
        const index = slots.map(s => s.hour).indexOf(startTime + duration)
        if(index >= 0) {
          const roomIndex = slots[index].rooms.map(d => d.roomId).indexOf(booking.roomId)
          if(roomIndex >= 0) {
            if(duration === 0) slots[index].rooms[roomIndex].booking = booking
            else slots[index].rooms[roomIndex].status = 'unavailable'
          }
        }
      }
      while (duration > 0)
    })
    setSlotsState(slots.sort(({hour:a}, {hour:b}) => b+a))
  }

  const addDaysToDate = (days:number) => {
    let d = new Date(selectedDate)
    d.setDate(d.getDate() + days)
    setSelectedDate(d)
  }

  useEffect(() => {
    const filtersDate = new Date(selectedFilters[0].value)
    if(selectedDate >= thresholdDate && (filtersDate > selectedDate || filtersDate < thresholdDate)) {
      setSelectedFilters([{field:'startDate', operator:'>=', value:selectedDate.toISOString()}])
    } else if(selectedDate < thresholdDate) {
      const filters:Array<Filter> = []
      let date = new Date(selectedDate)
      date.setHours(5,0,0,0) // from 5:00 AM
      filters.push({field:'startDate', operator:'>=', value:date.toISOString()})
      date.setDate(date.getDate() + 1) // to next day 5:00 AM
      filters.push({field:'startDate', operator:'<=', value:date.toISOString()})
      setSelectedFilters(filters)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate])

  useEffect(() => {
    setSlots()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rooms, selectedDate, items])

  function getIcon(Icon?:IconType) {
    return (Icon && <Icon />)
  }

  return (
    <>
      <div className="absolute top-1.5 left-4 sm:left-1/2 sm:transform sm:-translate-x-1/2">
        <div className="flex items-center">
          <DateNavigator date={selectedDate} setDate={setSelectedDate}/>
        </div>
      </div>
      <div className='flex gap-1 justify-between text-xs p-1.5 min-h-[52px] overflow-x-auto'>
        {days.map(day => {
          return (
            <ButtonSmall key={day.toDateString()} active={day.toDateString() === selectedDate.toDateString()} onClick={() => setSelectedDate(day)}>
              <div className='text-center'>{day.getDate()}</div>
              <div>{getDayNameShort(day, i18n.resolvedLanguage)}</div>
            </ButtonSmall>
          )
        })}
      </div>
      <div className='flex'>
        <MobileSliderX className='flex-grow bg-slate-100 dark:bg-shark-900' slideLeft={() => addDaysToDate(1)} slideRight={() => addDaysToDate(-1)}>
          <div className="p-4">
            <div className="flex pl-11 sm:pr-11 border-b">
              <div className="flex flex-grow justify-around">
                {slotsState[0]?.rooms.map(room => {
                  return <div key={room.roomId} className="px-2"><span className='hidden sm:flex'>{room.roomName}</span><span className='sm:hidden'>{room.number}</span></div>
                })}
              </div>
            </div>
            {slotsState.map((slot, i) => {
              return (
                <div key={slot.hour} className={`flex py-0.5 ${slot.stringHour.split(':')[1] === '30' ? 'border-b' : '' }`}>
                  <div className="w-11">{ i === 0 || slot.stringHour.split(':')[1] === '00' ?  <span>{slot.stringHour}</span> : ''}</div>
                  <div className="flex flex-grow justify-around gap-2">
                    {slot.rooms.map(room => {
                      return (
                        <div key={slot.hour + room.number} className="flex flex-1 relative">
                          {room.status === 'available' ? (
                            <div className="flex flex-1 relative">
                              {room.booking ? (
                                <button 
                                  type="button"
                                  onClick={() => setCurrentBooking(room.booking)} 
                                  className={'absolute z-[2] top-0 left-0 w-full focus:outline-none focus:ring-4 hover:ring-4 focus:ring-corporate-300 hover:ring-corporate-300 rounded p-0.5 border-2 flex flex-col '+colorsMap.get(room.booking.color)?.bg+' '+colorsMap.get(room.booking.color)?.border+' '+colorsMap.get(room.booking.color)?.textDefault}
                                  style={{height: `${((room.booking.duration/30 * 32) + ((room.booking.duration/30 - 1) * 4.5))}px`}}>
                                  <div className='w-full flex justify-between gap-2 whitespace-nowrap overflow-hidden'>
                                    <div className='whitespace-nowrap overflow-hidden'>{room.booking.clientName}</div>
                                    <div className='flex gap-1'>
                                      {room.booking.tags?.map(tag => {
                                        return (
                                          <div key={room.booking?.id + tag}>{getIcon(iconsMap.get(tag))}</div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                  {room.booking.duration/30 > 1 && <div className='text-xs italic w-full h-[calc(100%-24px)] text-left overflow-hidden'>{room.booking.remarks}</div>}
                                </button>
                              ) : (
                                <button 
                                  type="button"
                                  className={`w-full h-[32px] justify-center font-semibold border-2 border-transparent hover:border-gray-300 focus:border-gray-300 rounded focus:outline-none focus:ring-4 focus:ring-corporate-300`}
                                  onClick={() => newBooking(room.roomId, room.roomName, slot.stringHour)}
                                />
                              )}
                            </div>
                          ) : <div className="flex flex-1"></div>}
                        </div>
                      )
                    })}
                  </div>
                  <div className="hidden sm:block w-11 pl-2">{ i === 0 || slot.stringHour.split(':')[1] === '00' ?  <span>{slot.stringHour}</span> : ''}</div>
                </div>
              )
            })}
          </div>
        </MobileSliderX>
        {currentBooking && (
          <div className='absolute w-full z-[3] top-0 h-full overflow-y-auto flex sm:relative sm:inline sm:w-[300px]'>
            <BookingForm 
              booking={currentBooking} 
              submit={upsertHandler}
              cancel={() => {setCurrentBooking(undefined)}}
              />
          </div>
        )}
      </div>
    </>
  )
}

export default Calendar