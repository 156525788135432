import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import ApproveModal from "../components/global/ApproveModal";
import Table from "../components/global/Table";
import UserForm from "../components/UserForm";
import { useAlert } from "../context/AlertContext";
import { useFirebaseFunctions } from "../hooks/useFirebaseFunctions";
import { useFirestoreCollection, Filter } from "../hooks/useFirestoreCollection"
import { filterFieldsArray, filterTextArray, sortArray } from "../services/utils";
import { User } from "../types";

interface UseFirestoreCollectionType {
  items: Array<User>
  addItem: (item: User) => Promise<void>
  updateItem: (item: User) => Promise<void>
  deleteItem: (item: User) => Promise<void>
}

interface Sort {
  field: "name" | "email"
  order: number
}

function Users() {
  
  const { t } = useTranslation(['user', 'global'])

  let navigate = useNavigate()
  const { userId } = useParams()

  const { addAlert } = useAlert()
  const {items, updateItem, deleteItem} = useFirestoreCollection("users", undefined, undefined, [{field:'role', operator:'!=', value: 'superAdmin'}]) as UseFirestoreCollectionType
  const {addUser} = useFirebaseFunctions()
  const [sort, setSort] = useState<Sort>({field:'name', order:1})
  const [searchText, setSearchText] = useState<string>('')
  const [selectedFilters, setSelectedFilters] = useState<Array<Filter>>([])
  const [deleteUser, setDeleteUser] = useState<User|undefined>(undefined)
  const [currentUser, setCurrentUser] = useState<User|undefined>(undefined)

  const columns = [
    {label:t('name'), value:'name', sortable:true, highlight:true},
    {label:t('email'), value:'email', sortable:true, highlight:true},
    {label:t('role'), value:'role', sortable:true, translate:true, class:'w-[110px]', hideMobile: true},
  ]

  const filters = [
    {field: 'role', options: [
      {label:t('allRoles'), value:'allItems'},
      {label:t('editorRole'), value:'editor'},
      {label:t('adminRole'), value:'admin'},
    ]}
  ]

  const newUser:User = {
    name: '',
    email: '',
    role: 'editor',
    lang: 'ca',
    org: ''
  }

  const upsertHandler = async (user:User) => {
    try {
      if (user.id) {
        await updateItem(user)
        addAlert({type:"success", title:t('user_updated'), message:user.name || ''})
      } else {
        await addUser(user)
        addAlert({type:"success", title:t('user_created'), message:user.name || ''})
      }
      navigate(-1)
    } catch (error) {
      console.error(error)
      addAlert({type:"error", title:t('genericFirebaseError',{ns:'global'}), message:''})
    }
  }

  const deleteHandler = async (value:boolean) => {
    try {
      if(value && deleteUser) {
        await deleteItem(deleteUser)
        addAlert({type:"success", title:t('user_deleted'), message:deleteUser?.name || ''})
      }
      setDeleteUser(undefined)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if(items.length < 1) return
    if(userId) setCurrentUser(items.filter(i => i.id === userId)[0] || newUser)
    else if(currentUser) setCurrentUser(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userId, items])

  return (
    <>
      {deleteUser && <ApproveModal title={t('delete_user')} question={t('delete_user_question')} item={deleteUser.name} approve={deleteHandler}/>}
      {currentUser ?
        <UserForm 
          user={currentUser} 
          submit={upsertHandler}
          cancel={() => navigate(-1)}
        /> : 
        <Table 
          columns={columns} 
          items={sortArray(filterTextArray(filterFieldsArray(items, selectedFilters), searchText, ['name', 'email']), sort.field, sort.order)}
          searchText={searchText}
          setSearchText={setSearchText}
          sort={sort} 
          setSort={setSort as any} 
          editable={true}
          editItem={(item) => navigate(item.id)}
          newItem={() => navigate('new')}
          filters={filters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          deleteItem={setDeleteUser}
        />}
    </>
  )
}

export default Users
