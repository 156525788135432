import { Route, Routes, Navigate } from 'react-router-dom';
import Loading from './components/global/Loading';
import Calendar from './pages/Calendar';
import Home from './pages/Home';
import Login from './pages/Login';
import { useUser } from './context/AuthContext';
import Users from './pages/Users';
import { useAlert } from './context/AlertContext';
import Alert from './components/global/Alert';
import Orgs from './pages/Orgs';
import Logs from './pages/Logs';
import Rooms from './pages/Rooms';
import Clients from './pages/Clients';
import Bookings from './pages/Bookings';
import { useEffect } from 'react';
import { useAppDispatch } from './store/store';
import { useFirestoreCollection } from './hooks/useFirestoreCollection';
import { Client, Room } from './types';
import { setRooms } from './store/features/roomsSlice';
import { setClients } from './store/features/clientsSlice';
import Receipts from './pages/Receipts';
import ProtectedRoutes from './components/layout/ProtectedRoutes';
import Body from './components/layout/Body';

function App() {
  const { loading, user } = useUser()
  const { alerts, hideAlert } = useAlert()
  const {items:rooms} = useFirestoreCollection("rooms", {field:'number', order:1}) as {items:Array<Room>}
  const {items:clients} = useFirestoreCollection("clients", {field:'bookingsNumber', order:-1}) as {items:Array<Client>}
  const dispatch = useAppDispatch()

  useEffect(() => {
    if(user?.darkMode) document.documentElement.classList.add("dark")
    else if(user?.darkMode === false) document.documentElement.classList.remove("dark")
  }, [user])

  useEffect(() => {
    if(user) dispatch(setRooms(rooms))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user, rooms])

  useEffect(() => {
    if(user) dispatch(setClients(clients))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user, clients])

  return (
    <>
      {alerts.length > 0 && <Alert alerts={alerts} hideAlert={hideAlert} />}
      {loading && <Loading />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<ProtectedRoutes allow={loading || user ? true : false} />} >
          <Route path="" element={<Navigate to="calendar" replace />} />
          <Route path="calendar/:bookingId?" element={<Body><Calendar /></Body>} />
          <Route path="receipts/:receiptId?" element={<Body><Receipts /></Body>} />
          <Route path="bookings/:bookingId?" element={<Body><Bookings /></Body>} />
          <Route path="clients/:clientId?" element={<Body><Clients /></Body>} />
          <Route element={<ProtectedRoutes  allow={loading || user?.role === 'superAdmin' || user?.role === 'admin' ? true : false} />}>
            <Route path="rooms/:roomId?" element={<Body><Rooms /></Body>} />
            <Route path="users/:userId?" element={<Body><Users /></Body>} />
            <Route path="logs/:logId?" element={<Body><Logs /></Body>} />
          </Route>
          <Route element={<ProtectedRoutes  allow={user?.role === 'superAdmin' ? true : false} />}>
            <Route path="orgs" element={<Body><Orgs /></Body>} />
          </Route>
          <Route path="*" element={<Navigate to="calendar" replace />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
}

export default App;
