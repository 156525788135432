import { FaGuitar } from "react-icons/fa"
import { Price } from "../types"
import { GiGrandPiano, GiGuitarBassHead, GiSaxophone, GiTrumpet } from "react-icons/gi"
import { TbMicrophone2 } from "react-icons/tb"
import { CgPiano } from "react-icons/cg"

export const sortArray = (items:Array<any>, sortField:string, sortOrder:number) => {
  return [...items].sort(
    (a:any, b:any) => {
      if(!a[sortField]) return (sortOrder)
      if(!b[sortField]) return (-sortOrder)
      return (a[sortField].toString().toLowerCase() > b[sortField].toString().toLowerCase()) ? sortOrder : ((b[sortField].toString().toLowerCase() > a[sortField].toString().toLowerCase()) ? -sortOrder : 0)
    })
}

export const filterTextArray = (items:Array<any>, filterText:string, keys:Array<string>) => {
  if (!filterText || !items) return items

  let filterTextArray = filterText.split(' ') //convert 'text1 text2' to ["text1", "text2", ""]
  filterTextArray = filterTextArray.filter(entry => entry.trim() !== '') //remove empty elements
  if(filterTextArray.length < 1) return items

  return items.filter(item => {
    for (var key of keys) {
      if(!item[key]) continue
      let match = true
      for (var text of filterTextArray) {
        if(item[key].toString().toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").replaceAll("-","").indexOf(text.toLowerCase()) < 0) match = false
      }
      if(match) return true
    }
    return false
  })
}

export const filterFieldsArray = (items:Array<any>, filters:Array<{field:string, value:string | undefined}>) => {
  if (!filters || !items) return items

  return items.filter(item => {
    let match = true
    for (var filter of filters) {
      if(typeof filter.value==='boolean') {
        if(match) { // to work with more than one filter in AND condition (Apply all filters together)
          if(typeof item[filter.field]==='boolean') match = item[filter.field]===filter.value //to work with boolean values
          else if((filter.value && !item[filter.field])||(!filter.value && item[filter.field])) match = false //to work with existing field
        }
      }else{
        if(match) { // to work with more than one filter in AND condition (Apply all filters together)
          if(filter.value&&(!item[filter.field]||(item[filter.field].indexOf(filter.value) === -1))) match = false
          if((filter.value==='')&&(!item[filter.field])) match = false //if dont pass value return only if object has field, not if value match
          if((filter.value===undefined)&&(item[filter.field])) match = false //if value == undefined, return elements without field
        }
      }
    }
    return match
  })
}

export const highlightText = (text:string, highlight:string) => {
  if (!text || !highlight) return text

  let pattern = highlight.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&')
  pattern = pattern.split(' ').filter((t:any) => {
    return t.length > 0
  }).join('|')
  const regex = new RegExp(pattern, 'gi')
  return text.toString().normalize("NFD").replace(/\p{Diacritic}/gu, "").replace(regex, (match) => `<span class="font-bold text-corporate-600 bg-corporate-50">${match}</span>`)
}

const getLocale = (lang?:string): string => {
  const localeMap = new Map([
    ["ca", "ca-ES"],
    ["es", "es-ES"],
    ["en", "en-US"]
  ])
  return lang ? localeMap.get(lang) || 'es-ES' : 'es-ES'
}

export const colorsMap = new Map([
  ['FF6900', {bg:'bg-[#FF6900]', text:'text-[#FF6900]', border:'border-[#FF6900]', textDefault:'text-white'}],
  ['FCB900', {bg:'bg-[#FCB900]', text:'text-[#FCB900]', border:'border-[#FCB900]', textDefault:'text-white'}],
  ['7BDCB5', {bg:'bg-[#7BDCB5]', text:'text-[#7BDCB5]', border:'border-[#7BDCB5]', textDefault:'text-white'}],
  ['00D084', {bg:'bg-[#00D084]', text:'text-[#00D084]', border:'border-[#00D084]', textDefault:'text-white'}],
  ['8ED1FC', {bg:'bg-[#8ED1FC]', text:'text-[#8ED1FC]', border:'border-[#8ED1FC]', textDefault:'text-white'}],
  ['0693E3', {bg:'bg-[#0693E3]', text:'text-[#0693E3]', border:'border-[#0693E3]', textDefault:'text-white'}],
  ['ABB8C3', {bg:'bg-[#ABB8C3]', text:'text-[#ABB8C3]', border:'border-[#ABB8C3]', textDefault:'text-white'}],
  ['EB144C', {bg:'bg-[#EB144C]', text:'text-[#EB144C]', border:'border-[#EB144C]', textDefault:'text-white'}],
  ['F78DA7', {bg:'bg-[#F78DA7]', text:'text-[#F78DA7]', border:'border-[#F78DA7]', textDefault:'text-white'}],
  ['9900EF', {bg:'bg-[#9900EF]', text:'text-[#9900EF]', border:'border-[#9900EF]', textDefault:'text-white'}]
])

export const iconsMap = new Map([
  ['guitar', FaGuitar],
  ['bass', GiGuitarBassHead],
  ['mic', TbMicrophone2],
  ['piano', CgPiano],
  ['trumpet', GiTrumpet],
  ['saxo', GiSaxophone],
  ['grandPiano', GiGrandPiano]
])

export const getPrice = (value:number, lang?:string): string => {
  const locale = getLocale(lang)
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
  })
  return formatter.format(value)
}

export const getDateTime = (date:string | Date, lang?:string): string => {
  const locale = getLocale(lang)
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: '2-digit', 
    minute: '2-digit',
    hour12: false
  }
  return new Date(date).toLocaleString(locale, options)
}

export const getDateShort = (date:string | Date, lang?:string): string => {
  const locale = getLocale(lang)
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "2-digit",
    month: "2-digit"
  }
  const stringDate = new Date(date).toLocaleString(locale, options)
  return stringDate.charAt(0).toUpperCase() + stringDate.slice(1)
}

export const getDayName = (date:string | Date, lang?:string): string => {
  const locale = getLocale(lang)
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long"
  }
  const stringDate = new Date(date).toLocaleString(locale, options)
  return stringDate.charAt(0).toUpperCase() + stringDate.slice(1)
}

export const getDayNameShort = (date:string | Date, lang?:string): string => {
  const locale = getLocale(lang)
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short"
  }
  const stringDate = new Date(date).toLocaleString(locale, options)
  return stringDate.charAt(0).toUpperCase() + stringDate.slice(1)
}

export const getDateBooking = (date:string | Date, duration:number, lang?:string): string => {
  const locale = getLocale(lang)
  const baseDate = new Date(date)
  const options1: Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "2-digit",
    month: "2-digit"
  }
  const date1 = baseDate.toLocaleString(locale, options1)
  const options2: Intl.DateTimeFormatOptions = {
    hour: '2-digit', 
    minute: '2-digit',
    hour12: false
  }
  const date2 = baseDate.toLocaleString(locale, options2)
  return date1+' ('+date2+' - '+calculateEndTime(baseDate, duration)+') '
}

export const getDay = (date:string | Date, lang?:string): string => {
  const locale = getLocale(lang)
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    day: "2-digit",
    month: "2-digit"
  }
  const stringDate = new Date(date).toLocaleString(locale, options)
  return stringDate.charAt(0).toUpperCase() + stringDate.slice(1)
}

export const getDayNameFromNumber = (number:number, lang?:string): string => {
  const locale = getLocale(lang)
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long"
  }
  const date = new Date()
  date.setDate(date.getDate() - date.getDay())
  date.setDate(date.getDate() + number)
  const stringDate = new Date(date).toLocaleString(locale, options)
  return stringDate.charAt(0).toUpperCase() + stringDate.slice(1)
}

export const getWeekFromDate = (date:string | Date): Array<Date> => {
  const dates = []
  const d = new Date(date)
  d.setDate(d.getDate() - d.getDay() + 1) //Monday
  let num = 0
  do {
    const dd = new Date(d)
    dd.setDate(dd.getDate() + num)
    dates.push(dd)
    num ++
  }
  while (num < 7)
  return dates
}

export const getMonth = (date:string | Date, lang?:string): string => {
  const locale = getLocale(lang)
  const options1: Intl.DateTimeFormatOptions = {
    month: "long"
  }
  const date1 = new Date(date).toLocaleString(locale, options1)
  const options2: Intl.DateTimeFormatOptions = {
    year: "numeric"
  }
  const date2 = new Date(date).toLocaleString(locale, options2)
  return date1.charAt(0).toUpperCase() + date1.slice(1) + ' ' + date2
}

export const getStringDateForInput = (d: string | Date): string => {
  const date = new Date(d)
  const month = '' + (date.getMonth() + 1)
  const day = '' + date.getDate()
  const year = '' + date.getFullYear()
  return '0000'.substring(year.length)+year+'-'+'00'.substring(month.length)+month+'-'+'00'.substring(day.length)+day
}

export const getStringTimeForInput = (d: string): string => {
  const date = new Date(d)
  const hour = '' + date.getHours()
  const minutes = '' + date.getMinutes()
  return '00'.substring(hour.length)+hour+':'+'00'.substring(minutes.length)+minutes
}

export const getStringTimeForInputFromTime = (minutes: number) => {
  let hour = Math.floor(minutes/60)
  const minute = (minutes - (hour * 60)).toString()
  if(hour > 23) hour -= 24
  return '00'.substring(hour.toString().length)+hour+':'+'00'.substring(minute.length)+minute
}

export const getTimeFromInputString = (hour:string): number => {
  let hours = parseInt(hour.split(':')[0])
  if(hours < 5) hours += 24 // to work same day before 5:00 am 
  return hours * 60 + parseInt(hour.split(':')[1])
}

export const getTimeFromDate = (date:Date): number => {
  let hours = date.getHours()
  if(hours < 5) hours += 24 // to work same day before 5:00 am 
  return hours * 60 + date.getMinutes()
}

export const getHoursPricesObject = (prices: Array<Price>):any => {
  let result:any = {}
  prices.forEach(p => {
    if(!result[p.hours]) result[p.hours] = {}
    let startTime = getTimeFromInputString(p.startTime)
    const endTime = getTimeFromInputString(p.endTime)
    do {
      result[p.hours][startTime] = p.price
      startTime += 30
    }
    while (startTime <= endTime)
  })
  return result
}

export const calculateEndTime = (date:Date, duration:number): string => {
  const minutes = date.getHours() * 60 + date.getMinutes() + duration
  let hour = Math.floor(minutes/60)
  const minute = minutes - (hour * 60)
  if(hour > 23) hour -= 24
  return '00'.substring(hour.toString().length)+hour+':'+'00'.substring(minute.toString().length)+minute
}

export const getDaysArray = (date:Date, daysNumber:number): Array<Date> => {
  const days:Array<Date> = []
  let d = new Date(date)
  let n = daysNumber
  do {
    days.push(new Date(d))
    d.setDate(d.getDate() + 1)
    n--
  } while(n>0)
  return days
}

/* Intl.DateTimeFormatOptions: {
  weekday: 'narrow' | 'short' | 'long',
  era: 'narrow' | 'short' | 'long',
  year: 'numeric' | '2-digit',
  month: 'numeric' | '2-digit' | 'narrow' | 'short' | 'long',
  day: 'numeric' | '2-digit',
  hour: 'numeric' | '2-digit',
  minute: 'numeric' | '2-digit',
  second: 'numeric' | '2-digit',
  timeZoneName: 'short' | 'long',

  // Time zone to express it in
  timeZone: 'Asia/Shanghai',
  // Force 12-hour or 24-hour
  hour12: true | false,

  // Rarely-used options
  hourCycle: 'h11' | 'h12' | 'h23' | 'h24',
  formatMatcher: 'basic' | 'best fit'
} */