import NavMenu from "./NavMenu";

export default function Sidebar() {
  return (
    <div className="hidden sm:flex flex-col left-0 p-2 fixed h-full bg-corporate-500 overflow-auto">
      <div className="flex mb-2 w-[40px] h-[40px] justify-center items-center">
        <img src="/logo_white.png" width="30" height="30" alt="Logo" ></img>
      </div>
      <nav>
        <NavMenu />
      </nav>
    </div>
  )
}
