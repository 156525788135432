import { FaTimes } from "react-icons/fa"

interface Props {
  alerts: Array<{
    type: 'info' | 'success' | 'warn' | 'error'
    title: string
    message: string
    show: boolean
    id: string
  }>
  hideAlert: (value: string) => void
}

function Alert({alerts, hideAlert}: Props) {
  return (
    <div className="fixed z-50 right-0 top-2">
      {alerts.map(alert => {
        return (
          <div 
            key={alert.id} 
            onClick={() => hideAlert(alert.id)}
            className={`relative w-[300px] min-h-[70px] mb-2 p-2 rounded-lg border-l-[10px] border-solid flex justify-between items-center ease-in-out duration-300 ${alert.show ? 'right-2' : 'right-[-310px]'} ${alert.type === 'success' ? 'border-green-400 text-green-600 bg-green-100' : alert.type === 'warn' ? 'border-orange-300 text-orange-600 bg-orange-100' : alert.type === 'error' ? 'border-red-300 text-red-600 bg-red-100' : 'border-blue-300 text-blue-600 bg-blue-100'}`}
            >
            <div>
              <p><b>{alert.title}</b></p>
              <p>{alert.message}</p>
            </div>
            <button className="p-2" onClick={() => hideAlert(alert.id)} aria-label='close modal'><FaTimes /></button>
          </div>
        )
      })}
    </div>
  )
}

export default Alert