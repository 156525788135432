import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useStoredFirestoreCollection } from '../hooks/useFirestoreCollection'
import { useAlert } from '../context/AlertContext'
import { filterTextArray, sortArray } from '../services/utils'
import ApproveModal from '../components/global/ApproveModal'
import Table from '../components/global/Table'
import ClientForm from '../components/ClientForm'
import { Client } from '../types'
import { useAppSelector } from '../store/store'

interface UseFirestoreCollectionType {
  addItem: (item: Client) => Promise<void>
  updateItem: (item: Client) => Promise<void>
  deleteItem: (item: Client) => Promise<void>
}

interface Sort {
  field: "bookingsNumber" | "fullname"
  order: number
}

function Clients() {

  const { t } = useTranslation(['client', 'global'])
  let navigate = useNavigate()
  const { clientId } = useParams()

  const { addAlert } = useAlert()
  const { clients:items } = useAppSelector(state => state.clients)
  const { addItem, updateItem, deleteItem } = useStoredFirestoreCollection("clients") as UseFirestoreCollectionType
  const [sort, setSort] = useState<Sort>({field:'bookingsNumber', order:-1})
  const [searchText, setSearchText] = useState<string>('')
  const [deleteClient, setDeleteClient] = useState<Client|undefined>(undefined)
  const [currentClient, setCurrentClient] = useState<Client|undefined>(undefined)

  const columns = [
    {label:t('fullName'), value:'fullName', sortable:true, highlight:true},
    {label:t('email'), value:'email', hideMobile:true, highlight:true},
    {label:t('phone'), value:'phone', class:'w-[110px]', highlight:true},
  ]

  const newClient:Client = {
    name: '',
    musicBand: '',
    color: '7BDCB5',
    phone: '',
    fullName: '',
    state: 'Barcelona',
    country: 'España',
    balance: 0,
    bonusHours: 0,
    bonusPrice: 0,
    remainingBonusHours: 0,
    bookingsNumber: 0
  }

  const upsertHandler = async (client:Client) => {
    try {
      client.fullName = client.musicBand ? client.name + ' (' + client.musicBand + ')' : client.name
      client.fullNameNormalized = client.fullName.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").replaceAll(" ","")
      client.phoneNormalized = client.phone.replaceAll("-","")
      if (client.id) {
        await updateItem(client)
        addAlert({type:"success", title:t('client_updated'), message:client.fullName || ''})
      } else {
        await addItem(client)
        addAlert({type:"success", title:t('client_created'), message:client.fullName || ''})
      }
      navigate(-1)
    } catch (error) {
      console.error(error)
      addAlert({type:"error", title:t('genericFirebaseError',{ns:'global'}), message:''})
    }
  }

  const deleteHandler = async (value:boolean) => {
    try {
      if(value && deleteClient) {
        await deleteItem(deleteClient)
        addAlert({type:"success", title:t('client_deleted'), message:deleteClient?.fullName || ''})
      }
      setDeleteClient(undefined)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if(items.length < 1 && clientId && clientId !== 'new') return
    if(clientId) setCurrentClient(items.filter(i => i.id === clientId)[0] || newClient)
    else if(currentClient) setCurrentClient(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[clientId, items])  

  return (
    <>
      {deleteClient && <ApproveModal title={t('delete_client')} question={t('delete_client_question')} item={deleteClient.fullName} approve={deleteHandler}/>}
      {currentClient ?
        <ClientForm 
          client={currentClient} 
          submit={upsertHandler}
          cancel={() => navigate(-1)}
        /> : 
        <Table 
          columns={columns} 
          items={sortArray(filterTextArray(items, searchText, ['fullName', 'email', 'phone']), sort.field, sort.order)} 
          sort={sort} 
          setSort={setSort as any} 
          searchText={searchText}
          setSearchText={setSearchText}
          editable={true}
          editItem={(item) => navigate(item.id)}
          newItem={() => navigate('new')}
          deleteItem={setDeleteClient}
        />
      }
    </>
  )
}

export default Clients