import { useEffect, useState } from "react"
import { filterTextArray, highlightText } from "../../services/utils"

interface Props {
  label?: string
  name: string
  ariaLable: string
  options:  Array<Option>
  value?:string
  required?: boolean
  disabled?: boolean
  className?: string
  handler: (e:Option) => void;
}

interface Option {
  label: string 
  value: string
}

function FormInputSearchSelect({label, name, ariaLable, value, options, required, disabled, className, handler}: Props) {

  const [inputState, setInputState] = useState<string>('')
  const [results, setResults] = useState<Array<Option>>([])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputState(e.target.value)
    const getResults = e.target.value ? filterTextArray(options, e.target.value, ['label']).slice(0, 5) : []
    setResults(getResults)
    if(getResults.length < 1) handler({label:'', value:''})
  }

  const handleClick = (option:Option) => {
    setResults([])
    setInputState(option.label)
    handler(option)
  }

  useEffect(() => {
    if(options.length) setInputState(options.filter(o => o.value === value)[0]?.label || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[options, value])
  
  return (
    <label className="relative grid grid-cols-1 gap-1 self-end">
      {label && <b>{label} {required && <span className="text-red-500"> *</span>} </b>}
      <input 
        type="text"
        autoComplete="off"
        name={name}
        placeholder="Search"
        className={`p-1.5 border border-solid border-gray-300 rounded focus:outline-none focus:ring-4 focus:ring-corporate-300 focus:border-corporate-400 dark:bg-shark-950 ${className || ''}`}
        aria-label={ariaLable}
        value={inputState} 
        onChange={handleChange} 
        required={required ? true : undefined}
        disabled={disabled ? true : undefined}
      />
      {results.length > 0 && (
        <div className="absolute w-full top-[70px] border border-solid border-gray-300 bg-white dark:bg-shark-900 rounded pt-1">
          {results.map(res => {
            return (
              <div key={res.value}>
                <button 
                  type="button" 
                  onClick={() => handleClick(res)}
                  className="w-full text-start mb-1 px-2 focus:outline-none focus:ring-4 focus:ring-corporate-300 focus:bg-corporate-50 focus:font-bold focus:text-corporate-600 hover:bg-corporate-50 hover:font-bold hover:text-corporate-600 rounded"
                  ><span dangerouslySetInnerHTML={{ __html: highlightText(res.label, inputState || '') }}></span></button>
              </div>
            )
          })}
        </div>
      )}
    </label>
  )
}

export default FormInputSearchSelect