import { useState } from "react"
import { useTranslation } from 'react-i18next';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth'
import { auth } from "../services/firebase-config";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../context/AlertContext";

const firebaseErrorsMap = new Map([
  ["auth/invalid-email", "invalidEmail"],
  ["auth/user-not-found", "userNotFound"],
  ["auth/wrong-password", "wrongPassword"],
  ["auth/user-disabled", "userDisabled"],
])

export default function Login() {

  const { t } = useTranslation(['login']);
  const navigate = useNavigate()
  
  const { addAlert } = useAlert()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [inputsError, setInputsError] = useState('')
  const [ signInWithEmailAndPassword, user, loading, error ] = useSignInWithEmailAndPassword(auth)

  const submitHandler = async (e: React.FormEvent) => {
    e.preventDefault()
    setInputsError('')
    if(!email) return setInputsError(t('missingEmail') || '')
    if(!password) return setInputsError(t('missingPassword') || '')
    if(password.length < 6) return setInputsError(t('weakPassword') || '')
    try {
      await signInWithEmailAndPassword(email, password)
      navigate('/admin/calendar')
    } catch (error) {
      console.error(error)
      addAlert({type:"error", title:t('genericFirebaseError',{ns:'global'}), message:''})
    }
  }

  return (
    <main className='flex flex-col h-screen w-full pl-0 overflow-y-auto'>
      <form onSubmit={submitHandler} className="flex-1 bg-slate-100 flex flex-col justify-center items-center gap-3">
        <h1 className="text-2xl">{t('logIn')}</h1>
        {inputsError && <p className="text-red-400 w-full text-center">{inputsError}</p>}
        {error && <p className="text-red-400 w-full text-center">{firebaseErrorsMap.get(error.code) ? t(''+firebaseErrorsMap.get(error.code)) : error.message}</p>}
          <input 
            type="email" 
            name="email"
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            placeholder={t('mail') || 'Email'}
            className="outline-none text-slate-900 p-2 w-full max-w-[30ch] border-b-2 border-solid border-white focus:border-corporate-500" 
            aria-label="Email"
          />
          <input 
            type="password" 
            name="password"
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            placeholder={t('password') || 'Password'}
            className="outline-none text-slate-900 p-2 w-full max-w-[30ch] border-b-2 border-solid border-white focus:border-corporate-500" 
            aria-label="Password"
          />
          <button 
            type="submit" 
            className="outline-none w-full max-w-[30ch] text-white bg-corporate-500 focus:bg-corporate-600 focus:text-corporate-200 p-2"
          >{t('getIn')}</button>
        <p className="p-2 w-full max-w-[30ch] text-left">{t('forgotPassword')}</p>
      </form>
    </main>
  )
}
