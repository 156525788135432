
interface Props {
  name: string
  label?: string
  title?: string
  required?: boolean
  ariaLable: string
  checked: boolean
  onChange?: (value : React.ChangeEvent<HTMLInputElement>) => void
}

function ToggleInput({name, label, title, required, ariaLable, checked, onChange}: Props) {
  return (
    <div className="relative grid grid-cols-1 gap-1">
      {title && <b>{title} {required && <span className="text-red-500"> *</span>} </b>}
      <label className="relative h-[24px] inline-flex items-center cursor-pointer">
        <input 
          type="checkbox" 
          name={name}
          aria-label={ariaLable}
          checked={checked}
          onChange={onChange} 
          className="sr-only peer" />
        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-corporate-300 peer-hover:ring-4 peer-hover:ring-corporate-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-corporate-500"></div>
        <span className="ml-3 text-ellipsis max-w-[1000px] overflow-hidden whitespace-nowrap">{label}</span>
      </label>
    </div>
  )
}

export default ToggleInput