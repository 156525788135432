import { BsCalendar3, BsFileEarmarkCodeFill } from "react-icons/bs";
import { FaCashRegister, FaRegUser } from "react-icons/fa";
import { HiOutlineTicket } from "react-icons/hi";
import { RiSettings3Fill, RiUserSettingsLine } from "react-icons/ri";
import { BiBuildings } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import NavMenuItem from "./NavMenuItem";
import { useUser } from "../../context/AuthContext";
import { CgMusicSpeaker } from "react-icons/cg";

export default function NavMenu() {
  const location = useLocation()
  const { user } = useUser()

  return (
    <>
      <Link to="/admin/calendar" className="focus:outline-none group">
        <NavMenuItem Icon={BsCalendar3} active={location.pathname.includes("/admin/calendar")} />
      </Link>
      <Link to="/admin/clients" className="focus:outline-none group">
        <NavMenuItem Icon={FaRegUser} active={location.pathname.includes("/admin/clients")} />
      </Link>
      <Link to="/admin/receipts" className="focus:outline-none group">
        <NavMenuItem Icon={FaCashRegister} active={location.pathname.includes("/admin/receipts")}/>
      </Link>
      <Link to="/admin/bookings" className="focus:outline-none group">
        <NavMenuItem Icon={HiOutlineTicket} active={location.pathname.includes("/admin/bookings")} />
      </Link>
      {user && (user.role === 'superAdmin' || user.role === 'admin') && (
        <>
          <Link to="/admin/rooms" className="focus:outline-none group">
            <NavMenuItem Icon={CgMusicSpeaker} active={location.pathname.includes("/admin/rooms")} />
          </Link>
          <Link to="/admin/users" className="focus:outline-none group">
            <NavMenuItem Icon={RiUserSettingsLine} active={location.pathname.includes("/admin/users")} />
          </Link>
          <Link to="/admin/logs" className="focus:outline-none group">
            <NavMenuItem Icon={BsFileEarmarkCodeFill} active={location.pathname.includes("/admin/logs")} />
          </Link>
        </>
      )}
      {user && user.role === 'superAdmin' && (
        <>
          <NavMenuItem Icon={RiSettings3Fill}/>
          <Link to="/admin/orgs" className="focus:outline-none group">
            <NavMenuItem Icon={BiBuildings} active={location.pathname.includes("/admin/orgs")}/>
          </Link>
        </>
      )}
    </>
  )
}