import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "../store/store"
import { Client, Days } from "../types"
import Modal from "./global/Modal"
import FormSelect from "./global/FormSelect"
import FormInput from "./global/FormInput"
import { RxLapTimer } from "react-icons/rx"
import { TbCoins } from "react-icons/tb"
import { HiCreditCard } from "react-icons/hi"


interface Props {
  title: string
  client: Client
  editBonus: Boolean
  approve: (value:boolean) => void
  bonusChanges: (value:BonusResponse) => void
}

interface BonusResponse {
  client: Client
  payMethod: string
}


function BonnusModal({title, client, editBonus, approve, bonusChanges}: Props) {

  const { t } = useTranslation(['global'])
  const { rooms } = useAppSelector(state => state.rooms)
  const [bonusState, setBonusState] = useState<Client>(client)
  const [hoursOptions, setHoursOptions] = useState<Array<{label:string, value:string}>>([])
  const [priceOptions, setPriceOptions] = useState<Array<{label:string, value:string}>>([])
  const [hoursState, setHoursState] = useState<any>(undefined)
  const [payMethod, setPayMethod] = useState<string>('card')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    console.log(e.target.value)
    setBonusState({
      ...bonusState,
      [e.target.name]: parseFloat(e.target.value) || 0
    })
  }
  
  useEffect(() => {
    let hours:any = {};
    const hourOptions:Array<{label:string, value:string}> = []
    rooms.forEach(room => {
      Object.keys(room.days).forEach(day => {
        room.days[day as keyof Days].forEach(price => {
          if(price.hours > 1) {
            if(!hours[price.hours]) {
              hours[price.hours] = []
              hourOptions.push({label:price.hours+'h', value:price.hours.toString()})
            }
            if(hours[price.hours].indexOf(price.price) < 0) hours[price.hours].push(price.price)
          }
        })
      })
    })
    // set client bonus if not find because bonus price can change in a future
    if(client.bonusHours && !hours[client.bonusHours]) {
      hours[client.bonusHours] = [client.bonusPrice]
      hourOptions.push({label:client.bonusHours+'h', value:client.bonusHours.toString()})
    }
    if(client.bonusHours && hours[client.bonusHours].indexOf(client.bonusPrice) < 0) hours[client.bonusHours].push(client.bonusPrice)
    setHoursState(hours)
    if(!bonusState.bonusHours) setBonusState({...bonusState, bonusHours:parseFloat(hourOptions[0]?.value || '')})
    setHoursOptions(hourOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    if(!hoursState || !bonusState.bonusHours) return
    let prices:Array<{label:string, value:string}> = []
    hoursState[bonusState.bonusHours].sort((a:number,b:number) => a-b).forEach((price:number) => {
      prices.push({label:price+' €', value:price.toString()})
    })
    if(!bonusState.bonusPrice) setBonusState({...bonusState, bonusPrice:parseFloat(prices[0]?.value || '')})
    setPriceOptions(prices)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoursState, bonusState.bonusHours])
  
  return (
    <Modal type="info" title={title} close={()=> approve(false)} approve={()=> {approve(true); bonusChanges({client:bonusState, payMethod})}} reject={()=> approve(false)}>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
        <FormSelect 
          label={t('hours') || ''}
          name="bonusHours"
          ariaLable={t('hours') || ''} 
          options={hoursOptions}
          defaultValue={bonusState.bonusHours?.toString() || ''}
          handler={handleChange}
        />
        <FormSelect 
          label={t('price') || ''}
          name="bonusPrice"
          ariaLable={t('price') || ''} 
          options={priceOptions}
          defaultValue={bonusState.bonusPrice?.toString() || ''}
          handler={handleChange}
        />
        <div className="col-span-2">
          {editBonus ? (
            <FormInput 
              label={t('remainingHours') || ''}
              name="remainingBonusHours" 
              type="number" 
              ariaLable={t('remainingHours') || ''}
              Icon={RxLapTimer} 
              min="1" 
              step="1" 
              value={bonusState.remainingBonusHours?.toString()}
              onChange={handleChange} 
            />
          ) : (
            <div>
              <p><b>{t('pay_method')}</b></p>
              <div className="flex gap-4 mt-1">
                <button 
                  className={`border border-indigo-500 ${payMethod === 'card' ? 'text-white bg-indigo-500' : 'text-indigo-500'} focus:outline-none focus:ring-4 focus:ring-indigo-300 focus:bg-indigo-500 focus:text-white hover:bg-indigo-500 hover:text-white rounded py-1 px-4`}
                  type="button"
                  onClick={() => setPayMethod('card')}
                ><HiCreditCard className='inline-block mb-[2px] mr-[6px]' />{t('card')}</button>
                <button 
                  className={`border border-purple-500 ${payMethod === 'cash' ? 'text-white bg-purple-500' : 'text-purple-500'} focus:outline-none focus:ring-4 focus:ring-purple-300 focus:bg-purple-500 focus:text-white hover:bg-purple-500 hover:text-white rounded py-1 px-4`}
                  type="button"
                  onClick={() => setPayMethod('cash')}
                ><TbCoins className='inline-block mb-[2px] mr-[6px]' />{t('cash')}</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default BonnusModal