import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Form from "./global/Form"
import FormHeader from "./global/FormHeader"
import FormInput from "./global/FormInput"
import { Receipt } from "../types"
import { getStringDateForInput } from "../services/utils"
import { MdEuro } from "react-icons/md"
import FormSelect from "./global/FormSelect"
import { useAppSelector } from "../store/store"
import FormInputSearchSelect from "./global/FormInputSearchSelect"
import ApproveModal from "./global/ApproveModal"
import { useNavigate } from "react-router-dom"
import { HiCreditCard } from "react-icons/hi"
import { TbCoins } from "react-icons/tb"

interface Props {
  receipt:Receipt
  cancel: () => void
  submit: (value: Receipt) => void
}


function ReceiptForm({receipt, cancel, submit}:Props) {

  const { t } = useTranslation(['receipt', 'global', 'booking'])
  const navigate = useNavigate()
  const [receiptState, setReceiptState] = useState<Receipt>(receipt)
  const { clients } = useAppSelector(state => state.clients)
  const [clientOptions, setClientOptions] = useState<Array<{label:string, value:string}>>([])
  const [clientNotFoundModal, setClientNotFoundModal] = useState<boolean>(false)
  const [payMethod, setPayMethod] = useState<string>('card')

  const conceptTypeOptions = [
    { label:t('booking',{ns:'global'}), value:'booking' },
    { label:t('bonus',{ns:'global'}), value:'bonus' },
    { label:t('equipment_rental',{ns:'global'}), value:'equipment_rental' },
    { label:t('other',{ns:'global'}), value:'other' },
  ]

  const createClient = (value:boolean) => {
    setClientNotFoundModal(false)
    if(value) navigate("/admin/clients/new")
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!receiptState.clientId) return setClientNotFoundModal(true)
    submit(receiptState)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    let value
    if(e.target.type === "date") {
      let date = new Date(e.target.value)
      date.setHours(0,0,0,0)
      value = date.toISOString()
    }
    else value = e.target.value
    setReceiptState({
      ...receiptState,
      [e.target.name]: value
    })
  }

  const clientChange = (e:{label:string, value:string}) => {
    setReceiptState({
      ...receiptState, 
      clientId:e.value, 
      clientName:e.label
    })
  }

  useEffect(() => {
    const clientsOptions:Array<{label:string, value:string}> = []
    clients.forEach(client => clientsOptions.push({label:client.fullName, value:client.id || ''}))
    setClientOptions(clientsOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      {clientNotFoundModal && <ApproveModal title={t('client_not_found',{ns:'booking'})} question={t('client_not_found_question',{ns:'booking'})} approve={createClient}/>}
      <FormHeader title={receipt.id ? t('edit_receipt') : t('new_receipt')}/>
      <Form handleSubmit={handleSubmit} back={cancel}>
        <div className="sm:col-span-2 flex justify-between">
          <div className="flex gap-4">  
            <button 
              className={`border border-indigo-500 ${payMethod === 'card' ? 'text-white bg-indigo-500' : 'text-indigo-500'} focus:outline-none focus:ring-4 focus:ring-indigo-300 focus:bg-indigo-500 focus:text-white hover:bg-indigo-500 hover:text-white rounded py-1 px-4`}
              type="button"
              onClick={() => setPayMethod('card')}
            ><HiCreditCard className='inline-block mb-[2px] mr-[6px]' />{t('card',{ns:'global'})}</button>
            <button 
              className={`border border-purple-500 ${payMethod === 'cash' ? 'text-white bg-purple-500' : 'text-purple-500'} focus:outline-none focus:ring-4 focus:ring-purple-300 focus:bg-purple-500 focus:text-white hover:bg-purple-500 hover:text-white rounded py-1 px-4`}
              type="button"
              onClick={() => setPayMethod('cash')}
            ><TbCoins className='inline-block mb-[2px] mr-[6px]' />{t('cash',{ns:'global'})}</button>
          </div>
          <p className="font-bold">{t('price')} &nbsp; <span className="text-2xl text-green-500">{receiptState.price} €</span></p>
        </div>
        <FormInputSearchSelect 
          label={t('client',{ns:'global'}) || ''}
          name="clientId"
          ariaLable={t('client',{ns:'global'}) || ''} 
          options={clientOptions}
          value={receiptState.clientId}
          handler={clientChange}
          required />
        <div className="grid grid-cols-2 gap-4">
          <FormInput 
            label={t('date',{ns:'global'}) || ''} 
            type="date" 
            name="dateTime" 
            ariaLable={t('date',{ns:'global'}) || ''} 
            value={getStringDateForInput(receiptState.dateTime)} 
            onChange={handleChange} 
            required />
          <FormInput 
            label={t('price',{ns:'global'}) || ''} 
            type="number" 
            name="price" 
            ariaLable={t('price',{ns:'global'}) || ''} 
            Icon={MdEuro} 
            min="1" 
            value={receiptState.price} 
            onChange={handleChange} 
            required />
        </div>
        <FormSelect 
          label={t('conceptType') || ''}
          name="conceptType"
          ariaLable={t('conceptType') || ''} 
          options={conceptTypeOptions}
          defaultValue={receiptState.conceptType}
          handler={handleChange}
          required />
        <FormInput 
          label={t('concept') || ''} 
          type="text" 
          name="concept" 
          ariaLable={t('concept') || ''} 
          value={receiptState.concept} 
          onChange={handleChange} />
      </Form>
    </>
  )
}

export default ReceiptForm