import { useState } from "react"
import { useTranslation } from "react-i18next"
import Form from "./global/Form"
import FormHeader from "./global/FormHeader"
import FormInput from "./global/FormInput"
import { Booking, Client, Receipt } from "../types"
import { IoTicket } from "react-icons/io5"
import BonusModal from "./BonusModal"
import { useFirestoreCollection, useStoredFirestoreCollection } from "../hooks/useFirestoreCollection"
import { useAlert } from "../context/AlertContext"
import { MdOutlineCreditCardOff } from "react-icons/md"
import { getDateBooking, getPrice } from "../services/utils"
import { useNavigate } from "react-router-dom"
import ColorPicker from "./global/ColorPicker"

interface Props {
  client:Client
  cancel: () => void
  submit: (value: Client) => void
}

interface ClientFirestoreCollectionType {
  updateItem: (item: Client) => Promise<void>
}

interface ReceiptFirestoreCollectionType {
  addItem: (item: Receipt) => Promise<void>
}

interface BookingFirestoreCollectionType {
  items: Array<Booking>
}


function ClientForm({client, cancel, submit}:Props) {

  const { t, i18n } = useTranslation(['client', 'global', 'booking'])
  const { addAlert } = useAlert()
  let navigate = useNavigate()
  const [clientState, setClientState] = useState<Client>(client)
  const [bonusModal, setBonusModal] = useState<boolean>(false)
  const { updateItem } = useStoredFirestoreCollection("clients") as ClientFirestoreCollectionType
  const { addItem } = useStoredFirestoreCollection("receipts") as ReceiptFirestoreCollectionType
  const {items:bookings} = useFirestoreCollection("bookings", {field:'startDate', order:-1}, undefined, [{field:'clientId', value: client.id || '1234'},{field:'status', value:'unpaid'}]) as BookingFirestoreCollectionType

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    submit(clientState)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setClientState({
      ...clientState,
      [e.target.name]: e.target.type === 'tel' ? phoneFormatter(e.target.value, clientState[e.target.name as keyof Client]) : e.target.value
    })
  }

  const bonusChanges = async (value:{client:Client, payMethod:string}) => {
    let newBonus = false
    if(!client.remainingBonusHours) {
      value.client.remainingBonusHours = value.client.bonusHours
      value.client.balance += value.client.bonusPrice
      newBonus = true
    } else {
      value.client.balance += (value.client.remainingBonusHours * value.client.bonusPrice / value.client.bonusHours) - (client.remainingBonusHours * client.bonusPrice / client.bonusHours)
    }
    try {
      await updateItem(value.client)
      if(newBonus) {
        const receipt:Receipt = {
          clientId: value.client.id || '',
          clientName: value.client.fullName,
          price: value.client.bonusPrice,
          payMethod: value.payMethod,
          dateTime: new Date().toISOString(),
          conceptType: "bonus",
          concept: value.client.bonusHours + 'h'
        }
        await addItem(receipt)
      }
      addAlert({type:"success", title:newBonus ? t('bonus_created') : t('bonus_updated'), message:client.fullName || ''})
      setBonusModal(false)
      setClientState(value.client)
    } catch (error) {
      console.error(error)
      addAlert({type:"error", title:t('genericFirebaseError',{ns:'global'}), message:''})
    }
  }

  const phoneFormatter = (value:string, previousValue:any) => {
    if (!value) return value
    const currentValue = value.replace(/[^\d]/g, '') // only allows 0-9 inputs
    if (!previousValue || value.length > previousValue.length) {
      if (currentValue.length < 4) return currentValue // returns: "x", "xx", "xxx"
      if (currentValue.length < 6) return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}` // returns: "xxx", "xxx-x", "xxx-xx", "xxx-xxx"
      return `${currentValue.slice(0, 3)}-${currentValue.slice(3, 6)}-${currentValue.slice(6, 9)}` // returns: "xxx-xxx", xxx-xxx-x", "xxx-xxx-xx", "xxx-xxx-xxx", "xxx-xxx-xxx"
    } else return value
  }

  return (
    <>
      {bonusModal && <BonusModal title={clientState.remainingBonusHours ? t('edit_bonus') : t('new_bonus')} client={clientState} editBonus={clientState.remainingBonusHours > 0} approve={setBonusModal} bonusChanges={bonusChanges} />}
      <FormHeader title={client.id ? t('edit_client') : t('new_client')}/>
      <Form handleSubmit={handleSubmit} back={cancel}>
        <div className="col-span-2 md:col-span-1 flex justify-between gap-4">
          <ColorPicker 
            name="color"
            ariaLable={t('color') || ''} 
            value={clientState.color}
            onChange={handleChange}
          />
          {client.id && (
            <button 
              className={`text-yellow-500 border border-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 focus:bg-yellow-500 focus:text-white focus:dark:text-shark-950 hover:bg-yellow-500 hover:text-white hover:dark:text-shark-950 rounded py-1 px-4`}
              type="button"
              onClick={() => setBonusModal(true)}
            ><IoTicket className='inline-block mb-[2px] mr-[6px]' />{clientState.remainingBonusHours ? t('edit_bonus') : t('new_bonus')}</button>
          )}
        </div>
        <div className="col-span-2 md:col-span-1 flex justify-between">
          {clientState.bonusHours > 0 && <p className="font-bold">{t('bonus')} {clientState.bonusHours}h | {clientState.bonusPrice} € &nbsp; <span className="text-2xl text-yellow-500">{clientState.remainingBonusHours} h</span></p>}
          <p className="font-bold">{t('balance')} &nbsp; <span className={`text-2xl ${clientState.balance >= 0 ? 'text-green-500' : 'text-red-400'}`}>{clientState.balance} €</span></p>
        </div>
        <FormInput 
          label={t('firstName') || ''} 
          type="text" 
          name="name" 
          ariaLable={t('firstName') || ''} 
          value={clientState.name} 
          onChange={handleChange} 
          required />
        <FormInput 
          label={t('musicBand') || ''} 
          type="text" 
          name="musicBand" 
          ariaLable={t('musicBand') || ''} 
          value={clientState.musicBand} 
          onChange={handleChange} />
        <FormInput 
          label={t('email') || ''} 
          type="email" 
          name="email" 
          ariaLable={t('email') || ''} 
          value={clientState.email} 
          onChange={handleChange} />
        <FormInput 
          label={t('phone') || ''} 
          type="tel" 
          name="phone" 
          ariaLable={t('phone') || ''} 
          value={clientState.phone} 
          onChange={handleChange}  />
        <FormInput 
          label={t('nif') || ''} 
          type="text" 
          name="nif" 
          ariaLable={t('nif') || ''} 
          value={clientState.nif} 
          onChange={handleChange}  />
        <FormInput 
          label={t('address') || ''} 
          type="text" 
          name="address" 
          ariaLable={t('address') || ''} 
          value={clientState.address} 
          onChange={handleChange}  />
        <FormInput 
          label={t('city') || ''} 
          type="text" 
          name="city" 
          ariaLable={t('city') || ''} 
          value={clientState.city} 
          onChange={handleChange}  />
        <FormInput 
          label={t('postalCode') || ''} 
          type="text" 
          name="postalCode" 
          ariaLable={t('postalCode') || ''} 
          value={clientState.postalCode} 
          onChange={handleChange}  />
        <FormInput 
          label={t('state') || ''} 
          type="text" 
          name="state" 
          ariaLable={t('state') || ''} 
          value={clientState.state} 
          onChange={handleChange}  />
        <FormInput 
          label={t('country') || ''} 
          type="text" 
          name="country" 
          ariaLable={t('country') || ''} 
          value={clientState.country} 
          onChange={handleChange}  />
        {bookings.length > 0 && (
          <div className="col-span-2">
            <p className="mb-2"><b>{t('bookingsPending')}</b></p>
            <table>
              <thead className="table w-full h-[40px] table-fixed bg-corporate-500 text-white dark:text-shark-950 border-collapse sm:rounded-tl sm:rounded-tr">
                <tr className="text-left">
                  <th className="p-2 text-ellipsis max-w-[1000px] overflow-hidden whitespace-nowrap">{t('room',{ns:'global'})}</th>
                  <th className="p-2 text-ellipsis max-w-[1000px] overflow-hidden whitespace-nowrap w-[80px]">{t('status',{ns:'booking'})}</th>
                  <th className="p-2 text-ellipsis max-w-[1000px] overflow-hidden whitespace-nowrap w-[240px] hidden sm:table-cell">{t('date',{ns:'global'})}</th>
                  <th className="p-2 text-ellipsis max-w-[1000px] overflow-hidden whitespace-nowrap w-[80px]">{t('price',{ns:'global'})}</th>
                </tr>
              </thead>
              <tbody className="w-full max-h-[100px] overflow-y-auto">
                {bookings.map(item => {
                  return (
                    <tr key={item.id} className="table w-full table-fixed odd:bg-gray-50 odd:dark:bg-shark-950 focus:bg-corporate-50 focus:dark:bg-shark-700 focus:font-bold focus:text-corporate-600 hover:bg-corporate-50 hover:dark:bg-shark-700 hover:font-bold hover:text-corporate-600" onClick={() => navigate('/admin/bookings/'+item.id)}>
                      <td className="p-2 text-ellipsis max-w-[1000px] overflow-hidden whitespace-nowrap cursor-pointer">
                        <button type="button" className="max-w-full focus:outline-none focus:ring-4 focus:ring-corporate-300 focus:bg-corporate-50 focus:font-bold focus:text-corporate-600 hover:bg-corporate-50 hover:font-bold hover:text-corporate-600 rounded">
                          {item.roomName}
                        </button>
                      </td>
                      <td className="p-2 cursor-pointer w-[80px]"><span className={`px-2 py-1 rounded bg-red-400 text-white`}><MdOutlineCreditCardOff className='inline-block mb-[2px]' /></span></td>
                      <td className="p-2 text-ellipsis max-w-[1000px] overflow-hidden whitespace-nowrap cursor-pointer w-[240px] hidden sm:table-cell">{getDateBooking(item.startDate, item.duration, i18n.resolvedLanguage)}</td>
                      <td className="p-2 text-ellipsis max-w-[1000px] overflow-hidden whitespace-nowrap cursor-pointer w-[80px]">{getPrice(item.price, i18n.resolvedLanguage)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )}
      </Form>
    </>
  )
}

export default ClientForm