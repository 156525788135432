import React from 'react'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.css'
import './services/firebase-config'
import { AuthContextProvider } from './context/AuthContext'
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { AlertContextProvider } from './context/AlertContext'
import { Provider } from 'react-redux'
import { store } from './store/store'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'es', 'ca'],
    fallbackLng: 'en',
    detection: {
      order: ['localStorage', 'cookie', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie']
    },
    backend: {
      loadPath: '/translations/{{lng}}/{{ns}}.json',
    },
    react: { useSuspense: false }
  })

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <AlertContextProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </AlertContextProvider>
    </AuthContextProvider>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
    