import Modal from "./Modal"

interface Props {
  title: string
  question: string
  item?: string
  approve: (value:boolean) => void
}

function ApproveModal({title, question, item, approve}: Props) {

  return (
    <Modal type="warning" title={title} close={()=> approve(false)} approve={()=> approve(true)} reject={()=> approve(false)}>
      <p className="font-bold mb-4">{question}</p>
      { item && <p>{item}</p>}
    </Modal>
  )
}

export default ApproveModal