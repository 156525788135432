import { IconType } from "react-icons"

interface Props {
  Icon: IconType,
  active?: boolean
}

export default function NavMenuItem({Icon, active}: Props) {
  
  return (
    <div className={`cursor-pointer sm:group-focus:ring-2 sm:hover:ring-2 sm:group-focus:ring-corporate-300 sm:hover:ring-corporate-300 rounded-lg w-[40px] h-[40px] p-3 flex items-center justify-left text-base space-x-2 mb-2 ${active ? 'bg-white text-corporate-500' : 'bg-corporate-500 sm:bg-corporate-600 text-corporate-100 sm:hover:bg-corporate-100 sm:hover:text-corporate-500 sm:group-focus:bg-corporate-100 sm:group-focus:text-corporate-500'}`}>
      <Icon />
    </div>
  )
}