import NavMenu from "./NavMenu";

export default function Footer() {
  return (
    <footer>
      <nav className="flex flex-row sm:hidden bg-corporate-500 p-2 pb-4 justify-between overflow-x-auto">
        <NavMenu />
      </nav>
    </footer>
  )
}