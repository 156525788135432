import { FaRegTrashAlt } from "react-icons/fa"

interface Props {
  onClick?: () => void
  className?: string
}

function ButtonDelete({onClick, className}: Props) {
  return (
    <button 
      type="button"
      className={`text-red-400 focus:outline-none focus:ring-4 focus:ring-red-300 focus:bg-red-400 focus:text-white focus:dark:text-shark-950 hover:bg-red-400 hover:text-white hover:dark:text-shark-950 rounded p-2 ${className || ''}`}
      onClick={onClick}
    ><FaRegTrashAlt /></button>
  )
}

export default ButtonDelete