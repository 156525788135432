import { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import ButtonPrimary from "./ButtonPrimary"
import { IoIosArrowBack, IoIosSave } from "react-icons/io"
import ButtonSecondary from "./ButtonSecondary"

interface Props {
  children: ReactNode
  handleSubmit: (value: React.FormEvent<HTMLFormElement>) => void
  back: () => void
}

function Form({children, handleSubmit, back} : Props) {

  const { t } = useTranslation(['global'])

  return (
    <form onSubmit={handleSubmit} className="flex-1 flex flex-col bg-slate-100 dark:bg-shark-900">
      <div className="flex flex-grow flex-col p-4 overflow-y-auto">
        <div className="w-full max-w-[1000px] mx-auto grid grid-cols-1 sm:grid-cols-2 gap-4">
          {children}
        </div>
      </div>
      <div className="p-4 flex flex-row-reverse gap-4">
        <ButtonPrimary type="submit"><IoIosSave className='inline-block mb-[2px] mr-[6px]' />{t('save')}</ButtonPrimary>
        <ButtonSecondary type="button" onClick={back}><IoIosArrowBack className='inline-block mb-[2px] mr-[6px]' />{t('back')}</ButtonSecondary>
      </div>
    </form>
  )
}

export default Form