import { changeLanguage } from "i18next"
import { Dispatch, SetStateAction, useState } from "react"
import { useSignOut } from 'react-firebase-hooks/auth'
import { useTranslation } from "react-i18next"
import { FaRegUser, FaTimes, FaUser } from "react-icons/fa"
import { FiLogOut } from "react-icons/fi"
import { useUser } from "../../context/AuthContext"
import { auth, db } from "../../services/firebase-config"
import { doc, updateDoc } from "firebase/firestore";
import Select from "../global/Select"
import ButtonPrimary from "../global/ButtonPrimary"
import ToggleInput from "../global/ToggleInput"

interface Props {
  setShowModal: Dispatch<SetStateAction<boolean>>
}

export default function UserModal({setShowModal}: Props) {
  const { user } = useUser()
  const [signOut] = useSignOut(auth)
  const { t, i18n } = useTranslation(['login', 'global', 'user'])
  const [userState, setUserState] = useState<any>(user)

  const languages = [
    { value: 'ca', label: t('ca',{ns:'global'})},
    { value: 'es', label: t('es',{ns:'global'})},
    { value: 'en', label: t('en',{ns:'global'})},
  ]

  function selectHandler(value:string) {
    changeLanguage(value)
    const docRef = doc(db, "users", user?.id || '');
    updateDoc(docRef, {lang:value, updatedBy:user?.id || '', updatedDate: new Date().toISOString()})
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = (e.target  as HTMLInputElement).checked
    setUserState({...userState, darkMode: checked})
    setDarkMode(checked)
    const docRef = doc(db, "users", user?.id || '');
    updateDoc(docRef, {darkMode:checked, updatedBy:user?.id || '', updatedDate: new Date().toISOString()})
  }

  const setDarkMode = (value:boolean) => {
    if(value) document.documentElement.classList.add("dark")
    else document.documentElement.classList.remove("dark")
  }

  return (
    <>
      <div onClick={() => setShowModal(false)} className="fixed z-10 inset-0 bg-gray-600 bg-opacity-90"></div>
      <div className="fixed z-10 right-5 top-14 bg-white dark:bg-shark-900 rounded">
        <div className="bg-corporate-500 text-white py-2 px-4 flex justify-between rounded-t">
          <div className="flex text-xl">
            <FaUser size={24} className='mt-[2px] mr-3'/>{t('user',{ns:'global'})}
          </div>
          <button className="focus:outline-none focus:ring-4 hover:ring-4 focus:ring-corporate-300 focus:bg-corporate-600 focus:text-corporate-200 hover:ring-corporate-300 hover:bg-corporate-600 hover:text-corporate-200 rounded px-2" onClick={() => setShowModal(false)} aria-label='close modal'><FaTimes /></button>
        </div>
        <div className="flex flex-col items-center w-[220px] p-4 gap-4">
          <div className="p-5 w-[70px] rounded-full bg-corporate-500 text-white text-3xl"><FaRegUser /></div>
          <div className="text-center">
            <p>{user?.name}</p>
            <p>{user?.email}</p>
          </div>
          <Select defaultValue={i18n.resolvedLanguage} options={languages} handler={selectHandler} />
          <ToggleInput
            name="darkMode" 
            label={t('dark_mode',{ns:'user'}) || ''} 
            ariaLable={t('dark_mode',{ns:'user'}) || ''} 
            checked={userState.darkMode || false}
            onChange={handleChange} />
          <ButtonPrimary className="w-full" onClick={() => {setShowModal(false); signOut()}}><FiLogOut className='inline-block mb-[2px] mr-[6px]' />{t('logOut')}</ButtonPrimary>
        </div>
      </div>
    </>
  )
}