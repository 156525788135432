import { useState } from "react"
import Table from "../components/global/Table"
import OrgForm from "../components/OrgForm"
import { useAlert } from "../context/AlertContext"
import { useFirestoreCollection } from "../hooks/useFirestoreCollection"
import { filterTextArray } from "../services/utils"
import { Org } from "../types"

interface UseFirestoreCollectionType {
  items: Array<Org>
  addItem: (item: Org) => Promise<void>
  updateItem: (item: Org) => Promise<void>
  deleteItem: (item: Org) => Promise<void>
}

function Orgs() {
  const { addAlert } = useAlert()
  const {items, addItem, updateItem } = useFirestoreCollection("orgs") as UseFirestoreCollectionType
  const [searchText, setSearchText] = useState<string>('')
  const [currentOrg, setCurrentOrg] = useState<Org|undefined>(undefined)

  const columns = [
    {label:'Name', value:'name', highlight:true},
    {label:'Email', value:'email'},
    {label:'Phone', value:'phone', class:'w-[110px]', hideMobile: true},
  ]

  const upsertHandler = async (org:Org) => {
    try {
      if (org.id) {
        await updateItem(org)
        addAlert({type:"success", title:'Org Updated', message:org.name || ''})
      } else {
        await addItem(org)
        addAlert({type:"success", title:'Org Created', message:org.name || ''})
      }
      setCurrentOrg(undefined)
    } catch (error) {
      console.error(error)
    }
  }

  const newOrg:Org = {
    name: '',
    company: '',
    responsable: '',
    address: '',
    postalCode: '',
    city: '',
    country: '',
    cif: '',
    phone: '',
    email: '',
    timestamp: new Date().toISOString()
  }

  return (
    <>
      {currentOrg ?
        <OrgForm 
          org={currentOrg} 
          submit={upsertHandler}
          cancel={() => setCurrentOrg(undefined)}
        /> : 
        <Table 
          columns={columns} 
          items={filterTextArray(items, searchText, ['name'])}
          searchText={searchText}
          setSearchText={setSearchText}
          editable={true}
          editItem={setCurrentOrg}
          newItem={() => setCurrentOrg(newOrg)}
        />}
    </>
  )
}

export default Orgs