import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import Modal from "../components/global/Modal";
import Table from "../components/global/Table";
import { useFirestoreCollection, Filter } from "../hooks/useFirestoreCollection";
import { Log } from "../types";
import { useNavigate } from "react-router-dom";

interface UseFirestoreCollectionType {
  items: Array<Log>
  addItem: (item: Log) => Promise<void>
  updateItem: (item: Log) => Promise<void>
  sort: {
    field: string
    order: number
  }
  setSort: (value: {field:string, order:number}) => void
  selectedFilters: Array<Filter>
  setSelectedFilters: (value: Array<Filter>) => void
  setLimitItems: (value: any) => void
}


function Logs() {
  
  const { t } = useTranslation(['logs', 'global'])
  let navigate = useNavigate()
  const { logId } = useParams()
  const limitItems = 40

  const {items, sort, setSort, selectedFilters, setSelectedFilters, setLimitItems} = useFirestoreCollection("logs", {field:'timestamp', order:-1}, limitItems) as UseFirestoreCollectionType
  const [currentLog, setCurrentLog] = useState<Log|undefined>(undefined)

  const scrollBottom = (value:boolean) => {
    if(value) setLimitItems((prevState:number) => prevState + limitItems)
  }
  
  useEffect(() => {
    if(items.length < 1) return
    if(logId) setCurrentLog(items.filter(i => i.id === logId)[0] || undefined)
    else if(currentLog) setCurrentLog(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[logId, items])

  const columns = [
    {label:t('user',{ns:'global'}), value:'userName' },
    {label:t('email'), value:'userEmail', sortable:true, hideLarge:true},
    {label:t('date',{ns:'global'}), value:'timestamp', type:'dateTime', sortable:true, class:'w-[180px]', hideMobile: true},
    {label:t('action'), value:'action', translate:true, class:'w-[100px]'},
    {label:t('object'), value:'object', translate:true, class:'w-[110px]'},
    {label:t('changes'), value:'changes', hideLarge:true},
  ]

  const filters = [
    {field: 'action', options: [
      {label:t('allActions'), value:'allItems'},
      {label:t('create',{ns:'global'}), value:'create'},
      {label:t('update',{ns:'global'}), value:'update'},
      {label:t('delete',{ns:'global'}), value:'delete'},
    ]},
    {field: 'object', options: [
      {label:t('allObjects'), value:'allItems'},
      {label:t('user',{ns:'global'}), value:'user'},
      {label:t('client',{ns:'global'}), value:'client'},
      {label:t('room',{ns:'global'}), value:'room'},
      {label:t('booking',{ns:'global'}), value:'booking'},
      {label:t('receipt',{ns:'global'}), value:'receipt'},
    ]}
  ]

  return (
    <>
      {currentLog && (
        <Modal type="info" title={t('log')} close={() => navigate('/admin/logs')} approve={() => navigate('/admin/logs')}>
          <p className="text-2xl text-corporate-500 font-[500] mb-2">{currentLog.userName} ({currentLog.userEmail})</p>
          <p className="mb-4">{t(currentLog.action,{ns:'global'})} - <Link to={'/admin/'+currentLog.object+'s/'+currentLog.objectId} className="underline underline-offset-2 text-corporate-500">{t(currentLog.object,{ns:'global'})}</Link></p>
          {currentLog.changes.length > 0 && <p className="text-xl text-corporate-500 font-[500] mb-2">{t('changes')}:</p>}
          {currentLog.changes.map((change, i) => <p key={i}>{change}</p>)}
        </Modal>
      )}
      <Table 
        columns={columns} 
        items={items} 
        sort={sort} 
        setSort={setSort} 
        filters={filters}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        editItem={(item) => navigate(item.id)}
        setScrollBottom={scrollBottom}
      />
    </>
  )
}

export default Logs