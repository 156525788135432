import { ReactNode } from "react"

interface Props {
  children: ReactNode
  onClick?: () => void
  type?: "button" | "submit" | "reset" | undefined
  className?: string
}

function ButtonPrimary({children, onClick, type, className}: Props) {
  return (
    <button 
      className={`bg-corporate-500 text-white focus:outline-none focus:ring-4 hover:ring-4 focus:ring-corporate-300 hover:ring-corporate-300 py-1 px-4 rounded ${className || ''}`}
      type={type}
      onClick={onClick}
    >{children}</button>
  )
}

export default ButtonPrimary