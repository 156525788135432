import { ReactNode } from 'react'
import { IconType } from 'react-icons'

interface Props {
  onClick?: () => void
  Icon?: IconType
  children?:ReactNode
  active?: boolean
  className?: string
}

function ButtonSmall({Icon, onClick, active, children, className}: Props) {
  return (
    <button 
      className={`p-1 rounded focus:outline-none focus:ring-4 hover:ring-4 focus:ring-corporate-300 hover:ring-corporate-300 ${active ? 'bg-corporate-500 text-white' : ''} ${className || ''}`}
      type="button"
      onClick={onClick}
    >{Icon && <Icon />}{children}</button>
  )
}

export default ButtonSmall