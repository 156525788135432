import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { getStringDateForInput } from '../../services/utils'
import { useTranslation } from 'react-i18next'

interface Props {
  date:Date
  setDate: (value: Date) => void
}

function DateNavigator({date, setDate}:Props) {

  const { t } = useTranslation(['global'])
  const addDaysToDate = (days:number) => {
    let d = new Date(date)
    d.setDate(d.getDate() + days)
    setDate(d)
  }

  return (
    <>
      <button 
        type="button"
        className="text-white border border-corporate-500 bg-corporate-500 focus:outline-none focus:ring-4 hover:ring-4 focus:ring-corporate-300 hover:ring-corporate-300 rounded p-2.5"
        onClick={() => addDaysToDate(-1)}
      ><FaChevronLeft /></button>
      <input 
        type="date" 
        name="currentDate"
        aria-label={t('date') || ''}
        className="min-w-[120px] dark:dark-icons focus:outline-none focus:ring-4 focus:ring-corporate-300 px-1.5 py-[5px] border border-solid border-gray-300 focus:border-corporate-400 rounded mx-1.5 h-9 sm:h-[38px] bg-white dark:bg-shark-950" 
        value={getStringDateForInput(date)}
        onChange={(e) => e.target.value && setDate(new Date(e.target.value))} 
      />
      <button 
        type="button"
        className="text-white border border-corporate-500 bg-corporate-500 focus:outline-none focus:ring-4 hover:ring-4 focus:ring-corporate-300 hover:ring-corporate-300 rounded p-2.5"
        onClick={() => addDaysToDate(1)}
      ><FaChevronRight /></button>
    </>
  )
}

export default DateNavigator