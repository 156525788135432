import { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../services/firebase-config';
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from 'react-router-dom';
import { User } from "../types";
import { changeLanguage } from "i18next";

interface Props {
  children:ReactNode
}
interface UserContextType {
  loading: boolean
  user: User | undefined
  setLoading: (value: boolean) => void
}

const UserContext = createContext<UserContextType>({loading:true, user:undefined, setLoading(){}})

export const useUser = () => {
  return useContext(UserContext)
}

export const AuthContextProvider = ({children}: Props) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(undefined as undefined|User)
  const [authUser, authLoading] = useAuthState(auth)

  const getUser = async (uid:string) => {
    try {
      const docRef = doc(db, "users", uid);
      const docSnap = await getDoc(docRef);
      const user = docSnap.data()
      if(user) {
        user.id = uid;
        setUser(user as User)
        changeLanguage(user.lang)
        if(!window.location.pathname.includes("admin")) navigate('/admin')
      }
    } catch(error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if(!authLoading && !authUser) {
      setLoading(false)
      setUser(undefined)
    }
    else if(authUser) getUser(authUser.uid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[authUser, authLoading])


  return <UserContext.Provider value={{loading, user, setLoading}}>{children}</UserContext.Provider>
}