import { IconType } from 'react-icons'

interface Props {
  name: string
  label?: string
  ariaLable: string
  value?: string | number
  onChange?: (value : React.ChangeEvent<HTMLTextAreaElement>) => void
  placeholder?: string
  className?: string
  required?: boolean
  disabled?: boolean
  Icon?: IconType
}

function FormTextArea({name, label, ariaLable, value, onChange, placeholder, className, required, disabled, Icon}: Props) {
  return (
    <label className="relative grid grid-cols-1 gap-1">
      {label && <b>{label} {required && <span className="text-red-500"> *</span>} </b>}
      {Icon && <div className={`absolute left-2 ${label ? 'top-7' : 'top-0'} h-[38px] flex items-center`}><Icon /></div>}
      <textarea 
        name={name}
        placeholder={placeholder || ''}
        className={`p-1.5 min-w-[120px] w-full border border-solid border-gray-300 rounded focus:outline-none focus:ring-4 focus:ring-corporate-300 focus:border-corporate-400 bg-white dark:bg-shark-950 ${Icon && "pl-8"} ${className || ''}`}
        aria-label={ariaLable}
        autoComplete="off" 
        rows={3}
        value={value} 
        onChange={onChange} 
        required={required ? true : undefined}
        disabled={disabled ? true : undefined}
      />
    </label>
  )
}

export default FormTextArea