import {ReactNode, TouchEvent} from 'react'

interface Props {
  children:ReactNode
  slideLeft: () => void
  slideRight: () => void
  className?: string
}

function MobileSliderX({children, slideLeft, slideRight, className}: Props) {

  var touchEnable = false
  var touchStart = false
  var touchAxisX = 0
  setTimeout(() => touchEnable=true, 1000) // Eanble slider 1s after renders to avoid multiple events in iOS Safari

  const onTouchHandler = (event: TouchEvent<HTMLDivElement>) => {
    if(!touchEnable) return
    if(event.type === 'touchstart') {
      touchAxisX = event.touches[0].clientX
      touchStart = true
      setTimeout(() => touchStart=false, 500) // Yo have 0.5s to slide, to allow compatibility with drag and drop
    }
    else if(touchStart) {
      if(event.touches[0].clientX >= touchAxisX+100) {
        touchStart = false
        touchEnable = false 
        setTimeout(() => touchEnable=true, 1000) //Disable slider 1s after first event to avoid multiple events in iOS Safari
        slideRight()
      }
      if(event.touches[0].clientX <= touchAxisX-100) {
        touchStart = false
        touchEnable = false 
        setTimeout(() => touchEnable=true, 1000) //Disable slider 1s after first event to avoid multiple events in iOS Safari
        slideLeft()
      }
    }
  }

  return (
    <div className={className || ''} onTouchStart={onTouchHandler} onTouchMove={onTouchHandler}>
      {children}
    </div>
  )
}

export default MobileSliderX