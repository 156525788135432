import { CgCopy } from "react-icons/cg"

interface Props {
  onClick?: () => void
  className?: string
}

function ButtonCopy({onClick, className}: Props) {
  return (
    <button 
      type="button"
      className={`text-white bg-orange-400 focus:outline-none focus:ring-4 focus:ring-orange-300 focus:bg-orange-500 hover:bg-orange-500 rounded p-2 ${className || ''}`}
      onClick={onClick}
    ><CgCopy /></button>
  )
}
export default ButtonCopy