import { Navigate, Outlet } from 'react-router-dom'

interface Props {
  allow: boolean
}

function ProtectedRoutes({ allow }: Props) {
  if (!allow) {
    return <Navigate to="/" replace />
  }
  return <Outlet />
}
export default ProtectedRoutes