import { configureStore } from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { roomsSlice } from "./features/roomsSlice"
import { clientsSlice } from "./features/clientsSlice"

export const store = configureStore({
  reducer: {
    rooms: roomsSlice.reducer,
    clients: clientsSlice.reducer
  }
})

export const useAppDispatch:() => typeof store.dispatch = useDispatch
export const useAppSelector:TypedUseSelectorHook<ReturnType<typeof store.getState>> = useSelector