
interface Props {
  title: string
}

function FormHeader({title}: Props) {
  return (
    <div className="flex items-center absolute left-4 top-3 sm:left-[72px] text-corporate-500 font-medium text-2xl">{title}</div>
  )
}

export default FormHeader