import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { BsExclamationTriangleFill } from 'react-icons/bs'
import { FaCheck, FaInfoCircle, FaTimes } from 'react-icons/fa'
import ButtonPrimary from './ButtonPrimary'
import ButtonSecondary from './ButtonSecondary'

interface Props {
  children: ReactNode
  type: 'info' | 'warning'
  title: string
  close: () => void
  approve?: () => void
  reject?: () => void
}

function Modal({children, type, title, close, approve, reject}: Props) {

  const { t } = useTranslation(['global']);

  return (
    <>
      <div onClick={() => close()} className="fixed z-20 inset-0 bg-gray-600 bg-opacity-90"></div>
      <div className="fixed z-20 w-full max-w-[600px] left-1/2 -translate-x-1/2 top-12 bg-white dark:bg-shark-900 sm:rounded-md">
        <div className="bg-corporate-500 text-white dark:text-shark-950 py-2 px-4 flex justify-between sm:rounded-t-md">
          <div className="flex text-xl">
            {type === 'warning' && <BsExclamationTriangleFill size={24} className='mt-[2px] mr-3'/>}
            {type === 'info' && <FaInfoCircle size={24} className='mt-[2px] mr-3'/>}
            {title}
          </div>
          <button className="focus:outline-none focus:ring-4 focus:ring-corporate-300 focus:bg-corporate-600 focus:text-corporate-200 hover:bg-corporate-600 hover:text-corporate-200 rounded px-2" onClick={() => close()} aria-label='close modal'><FaTimes /></button>
        </div>
        <div className="py-4 px-6 max-h-[calc(100vh_-_210px)] overflow-auto">
          {children}
        </div>
        <div className="p-3 border-t border-solid border-gray-200 flex flex-row-reverse justify-between">
          {approve && <ButtonPrimary onClick={() => approve()}><FaCheck className='inline-block mb-[2px] mr-[6px]' />{t('ok')}</ButtonPrimary>}
          {reject && <ButtonSecondary onClick={() => reject()}><FaTimes className='inline-block mb-[2px] mr-[6px]'/>{t('cancel')}</ButtonSecondary>}
        </div>
      </div>
    </>
  )
}

export default Modal