import { useUser } from "../context/AuthContext"
import { User } from "../types";
import { httpsCallable } from "firebase/functions"
import { functions } from "../services/firebase-config";

export function useFirebaseFunctions () {
  const { user, setLoading } = useUser()

  const addUser = async (newUser: User) => {
    setLoading(true)
    newUser.org = user?.org || ''
    newUser.createdBy = user?.id || ''
    newUser.createdDate = new Date().toISOString()
    const addUser = httpsCallable(functions, 'addUser')
    try {
      await addUser({user:newUser})
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }

  return {addUser}
}