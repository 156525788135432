import { useEffect, useState } from "react"
import { addDoc, collection, doc, onSnapshot, query, setDoc, where, orderBy, limit, QueryConstraint, deleteDoc, and, or, QueryFilterConstraint, getDocs, WhereFilterOp } from "firebase/firestore"
import { useUser } from "../context/AuthContext"
import { User } from "../types"
import { db } from "../services/firebase-config"

export interface Filter {
  field:string
  value:string
  operator?:WhereFilterOp
}

interface CallItem {
  item: any
  path: string
  user: User | undefined
  setLoading:(value: boolean) => void
}

interface GetCollection {
  path: string
  user: User | undefined
  filtersAnd: Array<Filter>
  filtersOr: Array<Filter>
  setLoading:(value: boolean) => void
}

interface GetCollectionFilterDay {
  path: string
  user: User | undefined
  field: string
  isoDate: string
  filters: Array<Filter>
  setLoading:(value: boolean) => void
}

const addFirestoreItem = async ({item, path, user, setLoading}: CallItem) => {
  item.org = user?.org
  item.createdBy = user?.id || ''
  item.createdDate = new Date().toISOString()
  setLoading(true)
  try {
    await addDoc(collection(db, path), item)
  } catch (error) {
    console.error(error)
  } finally {
    setLoading(false)
  }
}

const updateFirestoreItem = async ({item, path, user, setLoading}: CallItem) => {
  item.updatedBy = user?.id || ''
  item.updatedDate = new Date().toISOString()
  setLoading(true)
  try {
    await setDoc(doc(db, path, item.id), item)
  } catch (error) {
    console.error(error)
  } finally {
    setLoading(false)
  }
}

const deleteFirestoreItem = async ({item, path, user, setLoading}: CallItem) => {
  let it = JSON.parse(JSON.stringify(item))
  it.updatedBy = user?.id || ''
  it.updatedDate = new Date().toISOString()
  setLoading(true)
  try {
    await setDoc(doc(db, path, item.id), it) //To save updatedBy and updatedDate for logs
    await deleteDoc(doc(db, path, item.id))
  } catch (error) {
    console.error(error)
  } finally {
    setLoading(false)
  }
}

const getFirestoreCollectionFilterAndOR = async ({path, user, filtersAnd, filtersOr, setLoading}: GetCollection) => {
  const queryConditions: Array<QueryConstraint> = [where("org", "==", user?.org)]
  filtersAnd.map(filter => queryConditions.push(where(filter.field, "==", filter.value)))
  const queryFilterConditions: Array<QueryFilterConstraint> = []
  filtersOr.map(filter => queryFilterConditions.push(where(filter.field, "==", filter.value)))
  const q = query( collection(db, path), and(...queryConditions as any, or(...queryFilterConditions)) )
  setLoading(true)
  try {
    const data:Array<any> = []
    const querySnapshot =  await getDocs(q)
    querySnapshot.forEach((doc) => {
      data.push({...doc.data(), id: doc.id})
    })
    return data
  } catch (error) {
    console.error(error)
  } finally {
    setLoading(false)
  }
}

const getFirestoreCollectionFilterByDay = async ({path, user, field, isoDate, filters, setLoading}: GetCollectionFilterDay) => {
  const queryConditions: Array<QueryConstraint> = [where("org", "==", user?.org)]
  filters.map(filter => queryConditions.push(where(filter.field, "==", filter.value)))
  let date = new Date(isoDate)
  date.setHours(0,0,0,0)
  queryConditions.push(where(field, ">=", date.toISOString()))
  date.setHours(23,59,59)
  queryConditions.push(where(field, "<=", date.toISOString()))
  const q = query( collection(db, path), ...queryConditions)
  setLoading(true)
  try {
    const data:Array<any> = []
    const querySnapshot =  await getDocs(q)
    querySnapshot.forEach((doc) => {
      data.push({...doc.data(), id: doc.id})
    })
    return data
  } catch (error) {
    console.error(error)
  } finally {
    setLoading(false)
  }
}

export function useFirestoreCollection (path: string, initSort?:{field:string, order:number}, itemsNumber?:number, initFilters?:Array<Filter>) {
  const { user, setLoading } = useUser()
  const [items, setItems] = useState([] as Array<any>)
  const [sort, setSort] = useState(initSort)
  const [selectedFilters, setSelectedFilters] = useState<Array<Filter>>(initFilters || [])
  const [limitItems, setLimitItems] = useState<number>(itemsNumber || 0)

  useEffect(() => {
    console.log('USE EFFECT!', path)
    //console.log(user, path, sort, selectedFilters)
    if(!user || !user.org) return
    setLoading(true)
    const queryConditions: Array<QueryConstraint> = []
    if(path !== 'orgs') queryConditions.push(where("org", "==", user?.org))
    selectedFilters.map(filter => queryConditions.push(where(filter.field, filter.operator || "==", filter.value)))
    if(initSort && sort) queryConditions.push(orderBy(sort.field, sort.order === 1 ? 'asc' : 'desc'))
    if(limitItems) queryConditions.push(limit(limitItems))
    const q = query( collection(db, path), ...queryConditions )

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      console.log('UPDATE')
      setLoading(false)
      const data:Array<any> = []
      querySnapshot.forEach((doc) => {
        data.push({...doc.data(), id: doc.id})
      })
      setItems(data)
    })

    return () => {
      //This is triggered when a component unmounts from the DOM
      console.log('UNMOUNT', path)
      unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user, path, sort, selectedFilters, limitItems])

  const addItem = (item: any) => addFirestoreItem({item, path, user, setLoading})
  const updateItem = (item: any) => updateFirestoreItem({item, path, user, setLoading})
  const deleteItem = (item: any) => deleteFirestoreItem({item, path, user, setLoading})

  return {items, addItem, updateItem, deleteItem, sort, setSort, selectedFilters, setSelectedFilters, setLimitItems}
}

export function useStoredFirestoreCollection (path: string) { 
  const { user, setLoading } = useUser()
  const addItem = (item: any) => addFirestoreItem({item, path, user, setLoading})
  const updateItem = (item: any) => updateFirestoreItem({item, path, user, setLoading})
  const deleteItem = (item: any) => deleteFirestoreItem({item, path, user, setLoading})
  return {addItem, updateItem, deleteItem}
}

export function useQueryFirestoreCollectionFilterAndOr (path: string) {
  const { user, setLoading } = useUser()
  const getCollectionFilterAndOr = (filtersAnd: Array<Filter>, filtersOr: Array<Filter>) => getFirestoreCollectionFilterAndOR({path, user, filtersAnd, filtersOr, setLoading})
  return {getCollectionFilterAndOr}
}

export function useQueryFirestoreCollectionFilterByDay (path: string) {
  const { user, setLoading } = useUser()
  const getCollectionFilterByDay = (field:string, isoDate:string, filters: Array<Filter>) => getFirestoreCollectionFilterByDay({path, user, field, isoDate, filters, setLoading})
  return {getCollectionFilterByDay}
}

/* 


private constructQuery() {

  const queryConditions: QueryConstraint[] = this.query.where.map((condition) =>
    where(condition.property, condition.operator, condition.value)
  );

  console.log(this.query);

  // You cannot order your query by other field if you include a filter with a range comparison (<, <=, >, >=)
  //if(queryConditions.length < 1 || queryConditions.filter((c:any) => c.ga.includes(">") || c.ga.includes("<")).length < 1) queryConditions.push(orderBy(this.query.orderField, this.query.reverse ? 'desc' : 'asc')); 

  console.log(queryConditions);

  const q: Query<DocumentData> = query(
    collection(this.firestore, this.query.path),
    ...queryConditions,
    orderBy(this.query.orderField, this.query.reverse ? 'desc' : 'asc'),
    limit(this.query.limit * this.query.page)
  );

  return q
} */