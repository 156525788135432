import { useState } from "react"
import { FaRegUser } from "react-icons/fa"
import UserModal from "./UserModal";

export default function Header() {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      {showModal && <UserModal setShowModal={setShowModal} />}
      <div className="drop-shadow-sm flex flex-row-reverse items-center py-2 px-4">
        <button onClick={() => setShowModal(true)} className="p-2.5 rounded-full bg-corporate-500 text-white focus:outline-none focus:ring-4 focus:ring-corporate-300 hover:ring-4 hover:ring-corporate-300"><FaRegUser /></button>
      </div>
    </>
  )
}