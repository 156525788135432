interface Props {
  label?: string
  name: string
  ariaLable: string
  options:  Array<{ 
    label: string, 
    value: string
  }>
  defaultValue?:string
  required?: boolean
  disabled?: boolean
  handler: (value: React.ChangeEvent<HTMLSelectElement>) => void;
}

function FormSelect({label, name, ariaLable, options, defaultValue, required, disabled, handler}: Props) {
  
  return (
    <label className="grid grid-cols-1 gap-1 self-end">
      {label && <b>{label} {required && <span className="text-red-500"> *</span>} </b>}
      {options.length && <select 
        name={name}
        aria-label={ariaLable}
        className='select-wrapper dark:select-wrapper-dark py-1.5 pl-2 pr-7 w-full border border-solid border-gray-300 rounded focus:outline-none focus:ring-4 focus:ring-corporate-300 focus:border-corporate-400 bg-white dark:bg-shark-950'
        value={defaultValue} 
        onChange={handler}
        required={required ? true : undefined}
        disabled={disabled ? true : undefined}
      >
        {options.map(({value, label}) => (
          <option key={label} value={value}>{label}</option>
        ))}
      </select>}
    </label>
  )
}

export default FormSelect