import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Room } from "../../types"

interface RoomsState {
  rooms: Array<Room>
}

const initialState: RoomsState = {
  rooms: []
}

export const roomsSlice = createSlice({
  name: "rooms",
  initialState,
  reducers : {
    setRooms: (state, action:PayloadAction<Array<Room>>) => {
      state.rooms = action.payload
    }
  }
})

export const { setRooms } = roomsSlice.actions

export default roomsSlice.reducer