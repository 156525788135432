import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Client } from "../../types"

interface ClientsState {
  clients: Array<Client>
}

const initialState: ClientsState = {
  clients: []
}

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers : {
    setClients: (state, action:PayloadAction<Array<Client>>) => {
      state.clients = action.payload
    }
  }
})

export const { setClients } = clientsSlice.actions

export default clientsSlice.reducer