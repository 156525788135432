import React from 'react'
import { IconType } from 'react-icons'

interface Props {
  type: "button" | "checkbox" | "color" | "date" | "datetime-local" | "email" | "file" | "hidden" | "image" | "month" | "number" | "password" | "radio" | "range" | "reset" | "search" | "submit" | "tel" | "text" | "time" | "url" | "week"
  name: string
  ariaLable: string
  onChange?: (value : React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (value : React.ChangeEvent<HTMLInputElement>) => void
  value?: string | number
  checked?: boolean
  placeholder?: string
  autoComplete?: "given-name" | "family-name" | "email" | "tel" | "organization"
  className?: string
  required?: boolean
  disabled?: boolean
  label?: string
  min?: string
  step?: string
  pattern?: string
  Icon?: IconType
}

function FormInput({type, name, ariaLable, value, onChange, onBlur, checked, placeholder, autoComplete, className, required, disabled, label, min, step, pattern, Icon}: Props) {
  return (
    <label className="relative grid grid-cols-1 gap-1">
      {label && <b>{label} {required && <span className="text-red-500"> *</span>} </b>}
      {Icon && <div className={`absolute left-2 ${label ? 'top-7' : 'top-0'} h-[38px] flex items-center`}><Icon /></div>}
      <input 
        type={type} 
        name={name}
        placeholder={placeholder || ''}
        className={`p-1.5 h-[38px] min-w-[120px] w-full border border-solid border-gray-300 rounded focus:outline-none focus:ring-4 focus:ring-corporate-300 focus:border-corporate-400 bg-white dark:bg-shark-950 dark:dark-icons ${Icon && "pl-8"} ${className || ''}`}
        aria-label={ariaLable}
        autoComplete={autoComplete || 'off'}
        value={value} 
        onChange={onChange} 
        onBlur={onBlur}
        checked={checked}
        min={min || undefined}
        step={step || undefined}
        pattern={pattern || undefined}
        required={required ? true : undefined}
        disabled={disabled ? true : undefined}
      />
    </label>
  )
}

export default FormInput