import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Price, Days, Room } from "../types"
import Form from "./global/Form"
import FormHeader from "./global/FormHeader"
import FormInput from "./global/FormInput"
import ColorPicker from "./global/ColorPicker"
import { getDayNameFromNumber } from "../services/utils"
import ButtonAdd from "./global/ButtonAdd"
import ButtonDelete from "./global/ButtonDelete"
import { BsCalendar3 } from "react-icons/bs"
import { RxLapTimer } from "react-icons/rx"
import { MdEuro } from "react-icons/md"
import ButtonCopy from "./global/ButtonCopy"
import ButtonPaste from "./global/ButtonPaste"
import ToggleInput from "./global/ToggleInput"
import Modal from "./global/Modal"
import { useAppSelector } from "../store/store"
import FormSelect from "./global/FormSelect"
import { useStoredFirestoreCollection } from "../hooks/useFirestoreCollection"
import { useAlert } from "../context/AlertContext"

interface Props {
  room:Room
  cancel: () => void
  submit: (value: Room) => void
}

interface RoomsFirestoreCollectionType {
  updateItem: (item: Room) => Promise<void>
}

function RoomForm({room, cancel, submit}:Props) {

  const { t, i18n } = useTranslation(['room', 'global'])
  const [roomState, setRoomState] = useState<Room>(room)
  const [pricesSelected, setPricesSelected] = useState<Price[]|undefined>(undefined)
  const { rooms } = useAppSelector(state => state.rooms)
  const [roomOptions, setRoomOptions] = useState<Array<{label:string, value:string}>>([])
  const [showRoomsModal, setShowRoomsModal] = useState<boolean>(false)
  const [selectedRoom, setSelectedRoom] = useState<Room>(room)
  const { updateItem:updateRoom } = useStoredFirestoreCollection("rooms") as RoomsFirestoreCollectionType
  const { addAlert } = useAlert()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    submit(roomState)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setRoomState({
      ...roomState,
      [e.target.name]: e.target.type === 'checkbox' ? (e.target  as HTMLInputElement).checked : e.target.type === 'number' ? parseInt(e.target.value) || '' : e.target.value
    })
  }

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, day:string, pos:number) => {
    setRoomState({
      ...roomState,
      days: {
        ...roomState.days, [day as keyof Days]: [
          ...roomState.days[day as keyof Days].slice(0, pos),
          {
            ...roomState.days[day as keyof Days][pos],
            [e.target.name]: e.target.type === 'number' ? parseFloat(e.target.value) : e.target.value,
          },
          ...roomState.days[day as keyof Days].slice(pos+1)
        ]
      }
    })
  }

  const newPrice:Price = {
    startTime: '',
    endTime: '',
    hours: 1,
    price: 0
  }

  const addPrice = (day:string) => {
    setRoomState({
      ...roomState,
      days: {
        ...roomState.days, [day as keyof Days]: [
          ...roomState.days[day as keyof Days], newPrice
        ]
      }
    })
  }

  const deletePrice = (day:string, pos:number) => {
    setRoomState({
      ...roomState,
      days: {
        ...roomState.days, [day as keyof Days]:[
          ...roomState.days[day as keyof Days].slice(0, pos),
          ...roomState.days[day as keyof Days].slice(pos+1)
        ]
      }
    })
  }

  const updateRoomPrices = async () => {
    if(!selectedRoom.id) return
    try {
      const roomObject = JSON.parse(JSON.stringify(selectedRoom))
      roomObject.days = roomState.days
      await updateRoom(roomObject)
      addAlert({type:"success", title:t('room_updated'), message:roomObject.name || ''})
      setShowRoomsModal(false)
    } catch (error) {
      console.error(error)
      addAlert({type:"error", title:t('genericFirebaseError',{ns:'global'}), message:''})
    }
  }

  const roomChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setSelectedRoom(rooms.filter(r => r.id === e.target.value)[0] || room)
  }

  useEffect(() => {
    const roomsOptions:Array<{label:string, value:string}> = [{label:'---', value:''}]
    rooms.forEach(room => roomsOptions.push({label:room.name, value:room.id || ''}))
    setRoomOptions(roomsOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  return (
    <>
      {showRoomsModal && (
        <Modal type="info" title={t('room',{ns:'global'})} close={() => setShowRoomsModal(false)} approve={() => updateRoomPrices()}>
          <FormSelect 
            label={t('room',{ns:'global'}) || ''}
            name="roomId"
            ariaLable={t('room',{ns:'global'}) || ''} 
            options={roomOptions}
            defaultValue={selectedRoom.id}
            handler={roomChange}
            required />
        </Modal>
      )}
      <FormHeader title={room.id ? t('edit_room') : t('new_room')}/>
      <Form handleSubmit={handleSubmit} back={cancel}>
        <FormInput 
          label={t('name') || ''} 
          type="text" 
          name="name" 
          ariaLable={t('name') || ''} 
          value={roomState.name} 
          onChange={handleChange} 
          required />
        <div className="grid grid-cols-4 gap-4">
          <FormInput 
            label={t('number') || ''} 
            type="number" 
            min="0"
            name="number" 
            ariaLable={t('number') || ''} 
            value={roomState.number} 
            onChange={handleChange} 
            className="min-w-[10px]"
            required />
          <ColorPicker 
            label={t('color') || ''}
            name="color"
            ariaLable={t('color') || ''} 
            value={roomState.color}
            onChange={handleChange}
          />
          <ToggleInput
            name="active" 
            title={t('active') || ''} 
            ariaLable={t('active') || ''} 
            checked={roomState.active}
            onChange={handleChange} 
          />
          <ButtonCopy className="self-end w-8 m-auto" onClick={() => setShowRoomsModal(true)} />
        </div>
        <div className="sm:col-span-2">
          <p><b>{t('price')}</b></p>
          {Object.keys(room.days).sort().map((day, i) => {
            return (
              <div key={day} className="mb-2">
                <p className="bg-corporate-500 text-white dark:text-shark-950 p-2 rounded-t"><BsCalendar3 className='inline-block mb-1 mx-1' /> {getDayNameFromNumber(i+1, i18n.resolvedLanguage)}</p>
                <div className="bg-white dark:bg-shark-950 p-3 rounded-b flex flex-col">
                  {roomState.days[day as keyof Days]?.map((price, ii) => {
                    return (
                      <div key={day+ii} className="w-full grid grid-cols-[auto_32px] gap-2 mb-2">
                        <div className="grid grid-cols-2 sm:grid-cols-4 gap-2">
                          <FormInput type="time" name="startTime" value={price.startTime} onChange={(e) => handlePriceChange(e, day, ii)} ariaLable="Start Time" required />
                          <FormInput type="time" name="endTime" value={price.endTime} onChange={(e) => handlePriceChange(e, day, ii)} ariaLable="End time" required />
                          <FormInput type="number" Icon={RxLapTimer} min="1" step="1" name="hours" value={price.hours} onChange={(e) => handlePriceChange(e, day, ii)} ariaLable="Hours" required />
                          <FormInput type="number" Icon={MdEuro} min="1" name="price" value={price.price} onChange={(e) => handlePriceChange(e, day, ii)} ariaLable="Price" required />
                        </div>
                        <ButtonDelete className="w-[32px] h-[32px] mt-1" onClick={() => deletePrice(day, ii)} />
                      </div>
                    )
                  })}
                  <div className="flex flex-row-reverse gap-4">
                    <ButtonAdd className="self-end" onClick={() => addPrice(day)} />
                    {!pricesSelected && <ButtonCopy className="self-end" onClick={() => setPricesSelected(roomState.days[day as keyof Days])} />}
                    {pricesSelected && <ButtonPaste className="self-end" onClick={() => {setRoomState({...roomState, days: {...roomState.days, [day as keyof Days]: pricesSelected}}); setPricesSelected(undefined)}} />}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </Form>
    </>
  )
}

export default RoomForm