import { ReactNode } from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import Footer from './Footer'

interface Props {
  children: ReactNode
}

function Body({children}: Props) {
  return (
    <>
      <Sidebar />
      <div className="flex flex-col h-screen w-full pl-0 bg-white dark:bg-shark-950 dark:text-white overflow-hidden sm:pl-[56px]">
        <Header />
        <main className='flex flex-grow flex-col overflow-y-auto'>
          {children}
        </main>
        <Footer />
      </div>
    </>
  )
}

export default Body