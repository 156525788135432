import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Form from "./global/Form"
import FormHeader from "./global/FormHeader"
import FormInput from "./global/FormInput"
import { Booking, Client, Days } from "../types"
import { getHoursPricesObject, getPrice, getStringDateForInput, getStringTimeForInput, getTimeFromDate, iconsMap } from "../services/utils"
import { MdCreditCard, MdEuro, MdOutlineCreditCardOff } from "react-icons/md"
import { IoTicket } from "react-icons/io5"
import FormSelect from "./global/FormSelect"
import { useAppSelector } from "../store/store"
import FormInputSearchSelect from "./global/FormInputSearchSelect"
import ApproveModal from "./global/ApproveModal"
import { useNavigate } from "react-router-dom"
import { useAlert } from "../context/AlertContext"
import { HiCreditCard } from "react-icons/hi"
import { TbCoins } from "react-icons/tb"
import { useQueryFirestoreCollectionFilterByDay, useStoredFirestoreCollection } from "../hooks/useFirestoreCollection"
import FormTextArea from "./global/FormTextArea"
import ButtonSmall from "./global/ButtonSmall"

interface Props {
  booking:Booking
  fullPage?: boolean
  cancel: () => void
  submit: (value: Booking) => void
}

interface ClientsFirestoreCollectionType {
  updateItem: (item: Client) => Promise<void>
}

interface BookingsFirestoreCollectionType {
  getCollectionFilterByDay: (
    field: string,
    isoDate: string,
    filters: Array<{field:string, value:string}>
  ) => Promise<Array<Booking>>
}


function BookingForm({booking, fullPage, cancel, submit}:Props) {

  const { t, i18n } = useTranslation(['booking', 'global'])
  const navigate = useNavigate()
  const { addAlert } = useAlert()
  const [bookingState, setBookingState] = useState<Booking>(booking)
  const [startTime, setStartTime] = useState<string>(getStringTimeForInput(bookingState.startDate))
  const { rooms } = useAppSelector(state => state.rooms)
  const [roomOptions, setRoomOptions] = useState<Array<{label:string, value:string}>>([])
  const { clients } = useAppSelector(state => state.clients)
  const [clientOptions, setClientOptions] = useState<Array<{label:string, value:string}>>([])
  const [clientNotFoundModal, setClientNotFoundModal] = useState<boolean>(false)
  const [selectedClient, setSelectedClient] = useState<Client|undefined>(undefined)
  const [payMethod, setPayMethod] = useState<string>('card')
  const { updateItem:updateClient } = useStoredFirestoreCollection("clients") as ClientsFirestoreCollectionType
  const { getCollectionFilterByDay } = useQueryFirestoreCollectionFilterByDay("bookings") as BookingsFirestoreCollectionType

  const createClient = (value:boolean) => {
    setClientNotFoundModal(false)
    if(value) navigate("/admin/clients/new")
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if(!bookingState.clientId) return setClientNotFoundModal(true)
    try {
      //Check existing bookings
      if(!booking.id || booking.startDate !== bookingState.startDate || booking.duration !== bookingState.duration || booking.roomId !== bookingState.roomId) {
        const existingBookings:Array<Booking> = await getCollectionFilterByDay("startDate", bookingState.startDate, [{field:"roomId", value:bookingState.roomId}])
        if(!roomAvailible(existingBookings, booking.id)) return addAlert({type:"error", title:t('room_already_reserved'), message:t('room_already_reserved_description')})
      }
      //Update client bonus
      if((bookingState.status === 'bonus' && booking.status !== 'bonus') || (bookingState.status !== 'bonus' && booking.status === 'bonus')) {
        let client:Client = JSON.parse(JSON.stringify(selectedClient))
        client.remainingBonusHours += bookingState.status === 'bonus' ? -(bookingState.duration / 60) : (bookingState.duration / 60)
        await updateClient(client)
      } else if(bookingState.status === 'bonus' && bookingState.duration !== booking.duration) {
        let client:Client = JSON.parse(JSON.stringify(selectedClient))
        client.remainingBonusHours += bookingState.status === 'bonus' ? (bookingState.duration / 60) : -(bookingState.duration / 60)
        await updateClient(client)
      }
      submit(bookingState)
    } catch (error) {
      console.error(error)
      addAlert({type:"error", title:t('genericFirebaseError',{ns:'global'}), message:''})
    }
  }

  const roomAvailible = (bookings:Array<Booking>, bookingId?:string): boolean => {
    const otherBooking = bookingId ? bookings.filter(b => b.id !== bookingId) : bookings
    const startHour = getTimeFromDate(new Date(bookingState.startDate))
    let duration = bookingState.duration
    let hours:any = {}
    do {
      duration -= 30
      hours[startHour+duration] = true
    }
    while (duration > 0)

    for (const booking of otherBooking) {
      const bookingStart = getTimeFromDate(new Date(booking.startDate))
      let bookingDuration = booking.duration
      let breakLoop = false
      do {
        bookingDuration -= 30
        if(hours[bookingStart+bookingDuration]) {
          breakLoop = true
          break
        }
      }
      while (bookingDuration > 0)
      if(breakLoop) return false
    }
    return true
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    setBookingState({
      ...bookingState,
      [e.target.name]: e.target.value
    })
  }

  const dateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    let startDate = new Date(bookingState.startDate)
    let duration = bookingState.duration
    let totalPrice = bookingState.totalPrice
    switch(e.target.name) {
      case 'startDate':
        let splitDate:string[] = e.target.value.split('-')
        startDate.setFullYear(parseInt(splitDate[0]))
        startDate.setMonth(parseInt(splitDate[1])-1)
        startDate.setDate(parseInt(splitDate[2]))
				break
      case 'duration':
        duration = Math.floor((parseInt(e.target.value) || 0)/30)*30; // fracciones de 30 min
        if(bookingState.status === 'bonus' && selectedClient) {
          if(selectedClient && selectedClient.bonusPrice) totalPrice = (selectedClient.bonusPrice / selectedClient.bonusHours) * (bookingState.duration/60)
        }
				break
      case 'startTime':
        startDate.setHours(parseInt(e.target.value.split(':')[0]))
        startDate.setMinutes(e.target.value.split(':')[1] === '30' ? 30 : 0)
				break
    }
    const price = bookingPrice(bookingState.roomId, bookingState.clientId, startDate.toISOString(), duration, bookingState.status)
    if(bookingState.status !== 'bonus') totalPrice = price
    setBookingState({
      ...bookingState,
      startDate: startDate.toISOString(),
      duration: duration,
      price: price,
      totalPrice: totalPrice
    })
    setStartTime(getStringTimeForInput(startDate.toISOString()))
  }

  const roomChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    let totalPrice = bookingState.totalPrice
    const price = bookingPrice(e.target.value, bookingState.clientId, bookingState.startDate, bookingState.duration, bookingState.status)
    if(bookingState.status !== 'bonus') totalPrice = price
    setBookingState({
      ...bookingState,
      roomId: e.target.value,
      roomName: rooms.filter(r => r.id === e.target.value)[0]?.name || '',
      price: price,
      totalPrice: totalPrice
    })
  }

  const statusChange = (status:"paid" | "unpaid" | "bonus") => {
    let price = bookingState.price
    let totalPrice = bookingState.totalPrice
    if(status === 'bonus' || bookingState.status === 'bonus') {
      price = bookingPrice(bookingState.roomId, bookingState.clientId, bookingState.startDate, bookingState.duration, status)
      totalPrice = price
      if(status === 'bonus' && selectedClient) {
        if(selectedClient && selectedClient.bonusPrice) totalPrice = (selectedClient.bonusPrice / selectedClient.bonusHours) * (bookingState.duration/60)
      }
    }
    setBookingState({
      ...bookingState,
      status: status,
      price: price,
      totalPrice: totalPrice
    })
  }

  const clientChange = (e:{label:string, value:string}) => {
    const client = clients.filter(c => c.id === e.value)[0] || undefined
    setSelectedClient(client)
    const price = bookingPrice(bookingState.roomId, e.value, bookingState.startDate, bookingState.duration, bookingState.status)
    let totalPrice = price
    if(bookingState.status === 'bonus' && client && client.bonusPrice) totalPrice = (client.bonusPrice / client.bonusHours) * (bookingState.duration/60)
    setBookingState({
      ...bookingState, 
      clientId:e.value, 
      clientName:e.label,
      color: client?.color || '7BDCB5',
      price: price,
      totalPrice: totalPrice
    })
  }

  const bookingPrice = (roomId:string, clientId:string, startDate:string, duration:number, status:string): number => {
    const room = rooms.filter(r => r.id === roomId)[0] || undefined
    if(!room) return 0
    const date = new Date(startDate)
    const day = date.getDay() === 0 ? '6' : date.getDay() - 1
    const prices = getHoursPricesObject(room.days['day'+day as keyof Days])
    let startTime = getTimeFromDate(date)
    const endTime = startTime + duration
    let price = 0
    //checkBonus
    if(status === 'bonus') {
      let bonus = ''
      const client = clients.filter(c => c.id === clientId)[0] || undefined
      if(client && prices[client.bonusHours]) bonus = client.bonusHours.toString()
      else {
        Object.keys(prices).sort().forEach(price => {
          if(price !== '1' && !bonus) bonus = price
        })
      }
      do {
        price += prices[bonus][startTime] ? prices[bonus][startTime] / (parseInt(bonus) * 2) : 0
        startTime += 30
      }
      while (startTime < endTime)
      return price
    }
    //check 1 hour prices
    if(!prices[1] || !prices[1][startTime] || !prices[1][endTime]) {
      addAlert({type:"warn", title:t('room_not_available'), message:t('room_not_available_description')})
      return 0
    }
    let allHoursAvailable = true
    do {
      if(prices[1][startTime]) price += prices[1][startTime]/2
      else allHoursAvailable = false
      startTime += 30
    }
    while (startTime < endTime)
    if(!allHoursAvailable) addAlert({type:"warn", title:t('room_not_available'), message:t('room_not_fully_available_description')})
    return price
  }

  const upsertTag = (tag:string) => {
    let tags = bookingState.tags || []
    if(tags.includes(tag)) tags = tags.filter(e => e !== tag)
    else tags.push(tag)
    setBookingState({...bookingState, tags:tags})
  }

  useEffect(() => {
    const roomsOptions:Array<{label:string, value:string}> = [{label:'---', value:''}]
    rooms.forEach(room => roomsOptions.push({label:room.name, value:room.id || ''}))
    setRoomOptions(roomsOptions)
    const clientsOptions:Array<{label:string, value:string}> = []
    clients.forEach(client => clientsOptions.push({label:client.fullName, value:client.id || ''}))
    setClientOptions(clientsOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    const newBooking:Booking = JSON.parse(JSON.stringify(booking))
    const client = clients.filter(c => c.id === newBooking.clientId)[0] || undefined
    setSelectedClient(client)
    if(!newBooking.price) newBooking.price = bookingPrice(newBooking.roomId, newBooking.clientId, newBooking.startDate, newBooking.duration, newBooking.status)
    if(!newBooking.totalPrice) newBooking.totalPrice = newBooking.price
    setBookingState(newBooking)
    setStartTime(getStringTimeForInput(newBooking.startDate))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[booking])

  return (
    <>
      {clientNotFoundModal && <ApproveModal title={t('client_not_found')} question={t('client_not_found_question')} approve={createClient}/>}
      {fullPage && <FormHeader title={booking.id ? t('edit_booking') : t('new_booking')}/>}
      <Form handleSubmit={handleSubmit} back={cancel}>
        <div className="sm:col-span-2 flex justify-between">
          <div className="flex gap-2">
            <button 
              className={`border border-red-400 ${bookingState.status === 'unpaid' ? 'text-white bg-red-400' : 'text-red-400'} focus:outline-none focus:ring-4 focus:ring-red-300 hover:ring-4 hover:ring-red-300 rounded py-1 px-2`}
              type="button"
              onClick={() => statusChange('unpaid')}
            ><MdOutlineCreditCardOff className='inline-block mb-[2px]' />{fullPage ? <span className="hidden sm:inline ml-1.5">{t('unpaid')}</span> : ''}</button>
            <button 
              className={`border border-green-500 ${bookingState.status === 'paid' ? 'text-white bg-green-500' : 'text-green-500'} focus:outline-none focus:ring-4 focus:ring-green-300 hover:ring-4 hover:ring-green-300 rounded py-1 px-2`}
              type="button"
              onClick={() => statusChange('paid')}
            ><MdCreditCard className='inline-block mb-[2px]' />{fullPage ? <span className="hidden sm:inline ml-1.5">{t('paid')}</span> : ''}</button>
            <button 
              className={`border border-yellow-500 ${bookingState.status === 'bonus' ? 'text-white bg-yellow-500' : 'text-yellow-500'} focus:outline-none focus:ring-4 focus:ring-yellow-300 hover:ring-4 hover:ring-yellow-300 rounded py-1 px-2`}
              type="button"
              onClick={() => statusChange('bonus')}
            ><IoTicket className='inline-block mb-[2px]' />{fullPage ? <span className="hidden sm:inline ml-1.5">{t('bonus')}</span> : ''}</button>
          </div>
          <p className={`font-bold text-right text-2xl ${bookingState.price === bookingState.totalPrice ? 'text-green-500' : 'text-red-400 line-through' }`}>{getPrice(bookingState.price, i18n.resolvedLanguage)}</p>
        </div>
        <div className="sm:col-span-2 flex justify-between">
          {bookingState.status === 'paid' ? (
            <div className="flex gap-2">  
              <button 
                className={`border border-indigo-500 ${payMethod === 'card' ? 'text-white bg-indigo-500' : 'text-indigo-500'} focus:outline-none focus:ring-4 focus:ring-indigo-300 hover:ring-4 hover:ring-indigo-300 rounded py-1 px-2 h-[34px] place-self-end mb-2`}
                type="button"
                onClick={() => setPayMethod('card')}
              ><HiCreditCard className='inline-block mb-[2px]' />{fullPage ? <span className="hidden sm:inline ml-1.5">{t('card',{ns:'global'})}</span> : ''}</button>
              <button 
                className={`border border-purple-500 ${payMethod === 'cash' ? 'text-white bg-purple-500' : 'text-purple-500'} focus:outline-none focus:ring-4 focus:ring-purple-300 hover:ring-4 hover:ring-purple-300 rounded py-1 px-2 h-[34px] place-self-end mb-2`}
                type="button"
                onClick={() => setPayMethod('cash')}
              ><TbCoins className='inline-block mb-[2px]' />{fullPage ? <span className="hidden sm:inline ml-1.5">{t('cash',{ns:'global'})}</span> : ''}</button>
            </div>
          ) : (
            <div>
              {bookingState.status === 'bonus' && selectedClient && (
                <>
                  <p><b>{t('bonus')} {selectedClient.bonusHours}h | {selectedClient.bonusPrice} €</b></p>
                  <p className="text-2xl text-yellow-500"><b>{selectedClient.remainingBonusHours} h</b></p>
                </>
                )}
            </div>
          )}
          <div className="w-[120px]">
            <FormInput 
              label={t('price',{ns:'global'}) || ''} 
              type="number" 
              name="price" 
              ariaLable={t('price',{ns:'global'}) || ''} 
              Icon={MdEuro} 
              value={bookingState.totalPrice} 
              onChange={(e) => setBookingState({...bookingState, totalPrice: parseInt(e.target.value) || 0 })} 
              required />
          </div>
        </div>
        <div className={!fullPage ? 'sm:col-span-2' : ''}>
          <FormInput 
            label={t('date',{ns:'global'}) || ''} 
            type="date" 
            name="startDate" 
            ariaLable={t('date',{ns:'global'}) || ''} 
            value={getStringDateForInput(bookingState.startDate)} 
            onChange={dateChange} 
            required />
        </div>
        <div className={`grid grid-cols-2 ${!fullPage && 'sm:col-span-2'} gap-4`}>
          <FormInput 
            label={t('startTime') || ''} 
            type="time" 
            name="startTime" 
            ariaLable={t('startTime') || ''} 
            value={startTime} 
            onChange={(e) => setStartTime(e.target.value)} 
            onBlur={dateChange} 
            required />
          <FormInput 
            label={t('duration') || ''} 
            type="number" 
            name="duration" 
            min="30" 
            step="30"
            pattern="[0-9]*"
            ariaLable={t('duration') || ''} 
            value={bookingState.duration} 
            onChange={handleChange}
            onBlur={dateChange} 
            required />
        </div>
        <div className={!fullPage ? 'sm:col-span-2' : ''}>
          <FormSelect 
            label={t('room',{ns:'global'}) || ''}
            name="roomId"
            ariaLable={t('room',{ns:'global'}) || ''} 
            options={roomOptions}
            defaultValue={bookingState.roomId}
            handler={roomChange}
            required />
        </div>
        <div className={!fullPage ? 'sm:col-span-2' : ''}>
          <FormInputSearchSelect 
            label={t('client',{ns:'global'}) || ''}
            name="clientId"
            ariaLable={t('client',{ns:'global'}) || ''} 
            options={clientOptions}
            value={bookingState.clientId}
            handler={clientChange}
            required />
        </div>
        <div className="relative grid grid-cols-1 gap-2 sm:col-span-2">
          <p><b>{t('tags')}</b></p>
          <div className="flex gap-2">
            {[...iconsMap.keys()].map(icon => {
              return (
                <ButtonSmall key={icon} Icon={iconsMap.get(icon)} active={bookingState.tags?.includes(icon)} onClick={() => upsertTag(icon)} />
                )
            })}
          </div>
        </div>
        <div className="sm:col-span-2">
          <FormTextArea 
            label={t('remarks') || ''} 
            name="remarks" 
            ariaLable={t('remarks') || ''} 
            value={bookingState.remarks || ''} 
            onChange={handleChange} />
        </div>
      </Form>
    </>
  )
}

export default BookingForm