import { useState } from "react"
import { Org } from "../types"
import Form from "./global/Form"
import FormHeader from "./global/FormHeader"
import FormInput from "./global/FormInput"
import { useUser } from "../context/AuthContext"
import { db } from "../services/firebase-config"
import { doc, updateDoc } from "firebase/firestore";

interface Props {
  org:Org
  cancel: () => void
  submit: (value: Org) => void
}

function OrgForm({org, cancel, submit}:Props) {
  const { user } = useUser()
  const [orgState, setOrgState] = useState<Org>(org)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    submit(orgState)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setOrgState({
      ...orgState,
      [e.target.name]: e.target.value
    })
  }


  function setOrg() {
    const docRef = doc(db, "users", user?.id || '');
    updateDoc(docRef, {org:org.id})
  }

  return (
    <>
      <FormHeader title={org.id ? 'Edit Org' : 'New Org'}/>
      <Form handleSubmit={handleSubmit} back={cancel}>
        <div className="sm:col-span-2 flex gap-4">
          <button 
            className={`border border-indigo-500 text-indigo-500 focus:outline-none focus:ring-4 focus:ring-indigo-300 focus:bg-indigo-500 focus:text-white hover:bg-indigo-500 hover:text-white rounded py-1 px-4`}
            type="button"
            onClick={() => setOrg()}
          >Assign ORG</button>
        </div>
        <FormInput 
          label="Name" 
          type="text" 
          name="name" 
          ariaLable="Name" 
          value={orgState.name} 
          onChange={handleChange} 
          required />
        <FormInput 
          label="Email" 
          type="email" 
          name="email" 
          ariaLable="Email" 
          value={orgState.email} 
          onChange={handleChange} />
        <FormInput 
          label="Responable" 
          type="text" 
          name="responsable" 
          ariaLable="Responable" 
          value={orgState.responsable} 
          onChange={handleChange} />
        <FormInput 
          label="Phone" 
          type="text" 
          name="phone" 
          ariaLable="Phone" 
          value={orgState.phone} 
          onChange={handleChange} />
        <FormInput 
          label="Company" 
          type="text" 
          name="company" 
          ariaLable="Company" 
          value={orgState.company} 
          onChange={handleChange} />
        <FormInput 
          label="CIF" 
          type="text" 
          name="cif" 
          ariaLable="CIF" 
          value={orgState.cif} 
          onChange={handleChange} />
        <FormInput 
          label="Address" 
          type="text" 
          name="address" 
          ariaLable="Address" 
          value={orgState.address} 
          onChange={handleChange} />
        <FormInput 
          label="City" 
          type="text" 
          name="city" 
          ariaLable="City" 
          value={orgState.city} 
          onChange={handleChange} />
        <FormInput 
          label="Country" 
          type="text" 
          name="country" 
          ariaLable="Country" 
          value={orgState.country} 
          onChange={handleChange} />
        <FormInput 
          label="Postal Code" 
          type="text" 
          name="postalCode" 
          ariaLable="Postal Code" 
          value={orgState.postalCode} 
          onChange={handleChange} />
      </Form>
    </>
  )
}

export default OrgForm