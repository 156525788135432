import { useTranslation } from 'react-i18next'
import { FaRegTrashAlt } from 'react-icons/fa'
import { TiArrowUnsorted, TiArrowSortedUp, TiArrowSortedDown, TiPlus } from 'react-icons/ti'
import { MdCheckBoxOutlineBlank, MdCreditCard, MdOutlineCreditCardOff } from 'react-icons/md'
import { getDateBooking, getDateTime, getPrice, highlightText } from '../../services/utils'
import TableSearch from './TableSearch'
import { IoMdCheckbox } from 'react-icons/io'
import { IoTicket } from 'react-icons/io5'
import { useRef } from 'react'
interface Props {
  columns:  Array<{ 
    label: string
    value: string
    type?: string
    translate?: boolean
    sortable?: boolean
    class?: string
    hideMobile?: boolean
    hideLarge?: boolean
    highlight?: boolean // highlight for searchText
  }>
  items: Array<any>
  editItem: (value:any) => void
  newItem?: () => void
  deleteItem?: (value:any) => void
  sort?: {
    field: string
    order: number
  }
  setSort?: (value: {field:string, order:number}) => void
  searchText?: string
  setSearchText?: (value:string) => void
  editable?: boolean // add New and Delete buttons
  filters?: Array<FilterObject>
  selectedFilters?: Array<{
    field:string
    value:string
  }>
  setSelectedFilters?: (value:Array<SelectedFilter>) => void
  setScrollBottom?: (value:boolean) => void
}

interface FilterObject {
  field: string
  options: Array<{
    label:string
    value:string
  }>
}
interface SelectedFilter {
  field:string
  value:string
}

function Table({columns, items, editItem, newItem, deleteItem, sort, setSort, searchText, setSearchText, editable, filters, selectedFilters, setSelectedFilters, setScrollBottom}: Props) {
  
  const { t, i18n } = useTranslation(['global'])
  const listInnerRef = useRef<HTMLTableSectionElement>(null)
  const onScroll = () => {
    if(!setScrollBottom) return
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        // TO SOMETHING HERE
        console.log('Reached bottom')
        setScrollBottom(true)
        setTimeout(() => setScrollBottom(false), 1000)
      }
    }
  }

  return (
    <div className="flex flex-1 sm:p-4 dark:bg-shark-900">
      {(setSearchText != null || setSelectedFilters != null) && <TableSearch searchText={searchText || ''} setSearchText={setSearchText} filters={filters} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />}
      <table className="block fixed h-[calc(100%-124px)] sm:relative sm:table sm:h-full w-full">
        <thead className="table w-full h-[40px] table-fixed bg-corporate-500 text-white dark:text-shark-950 border-collapse sm:rounded-tl sm:rounded-tr">
          <tr className="text-left">
            {columns.map(col => {
              return (
                <th className={`p-2 text-ellipsis max-w-[1000px] overflow-hidden whitespace-nowrap ${col.class || ''} ${col.hideMobile && 'hidden sm:table-cell'} ${col.hideLarge && 'hidden lg:table-cell'}`} key={col.value}>
                  {sort && col.sortable ? (
                      sort.field !== col.value ? (
                        <button className="focus:outline-none focus:ring-4 focus:ring-corporate-300 focus:bg-corporate-600 focus:text-corporate-200 hover:bg-corporate-600 hover:text-corporate-200 rounded pr-1" onClick={() => setSort && setSort({field:col.value, order:1})}><TiArrowUnsorted size={16} className='inline-block mb-[2px] mr-[2px]' />{col.label}</button>
                      ) : sort.order === 1 ? (
                        <button className="focus:outline-none focus:ring-4 focus:ring-corporate-300 focus:bg-corporate-600 focus:text-corporate-200 hover:bg-corporate-600 hover:text-corporate-200 rounded pr-1" onClick={() => setSort && setSort({field:col.value, order:-1})}><TiArrowSortedUp size={16} className='inline-block mb-[2px] mr-[2px]' />{col.label}</button>
                      ) : (
                        <button className="focus:outline-none focus:ring-4 focus:ring-corporate-300 focus:bg-corporate-600 focus:text-corporate-200 hover:bg-corporate-600 hover:text-corporate-200 rounded pr-1" onClick={() => setSort && setSort({field:col.value, order:1})}><TiArrowSortedDown size={16} className='inline-block mb-[2px] mr-[2px]' />{col.label}</button>
                      )
                  ) : (
                      <span>{col.label}</span>
                  )}
                </th>
              )
            })}
            {editable && <th className="p-2 w-[110px] text-end font-normal"><button className="bg-white dark:bg-shark-950 text-corporate-500 focus:outline-none focus:ring-4 focus:ring-corporate-300 focus:bg-corporate-600 focus:text-corporate-200 hover:bg-corporate-600 hover:text-corporate-200 rounded pl-2 pr-3 " onClick={() => newItem && newItem()}><TiPlus className='inline-block mb-[2px] mr-[2px]' />{t('add')}</button></th>}
          </tr>
        </thead>
        <tbody className="block w-full h-[calc(100%-40px)] overflow-y-auto" onScroll={() => onScroll()} ref={listInnerRef}>
          {
            items.map(item => {
              return (
                <tr key={item.id} className="table w-full table-fixed odd:bg-gray-50 odd:dark:bg-shark-950 focus:bg-corporate-50 focus:dark:bg-shark-700 focus:font-bold focus:text-corporate-600 hover:bg-corporate-50 hover:dark:bg-shark-700 hover:font-bold hover:text-corporate-600">
                  {columns.map((col, index) => {
                    return (
                      <td className={`p-2 text-ellipsis max-w-[1000px] overflow-hidden whitespace-nowrap cursor-pointer ${col.class || ''} ${col.hideMobile && 'hidden sm:table-cell'} ${col.hideLarge && 'hidden lg:table-cell'}`} key={item.id + col.value} onClick={() => editItem(item)}>
                        {index === 0 ? (
                          <button className="max-w-full focus:outline-none focus:ring-4 focus:ring-corporate-300 focus:bg-corporate-50 focus:font-bold focus:text-corporate-600 hover:bg-corporate-50 hover:font-bold hover:text-corporate-600 rounded"><div className="text-ellipsis max-w-[1000px] overflow-hidden whitespace-nowrap" dangerouslySetInnerHTML={{ __html: highlightText(item[col.value], (col.highlight && searchText) || '') }}></div></button>
                        ) : (
                          col.type === 'price' ? (
                            getPrice(item[col.value], i18n.resolvedLanguage)
                          ) : (
                            col.type === 'dateTime' ? (
                              getDateTime(item[col.value], i18n.resolvedLanguage)
                            ) : (
                              col.type === 'dateBooking' ? (
                                getDateBooking(item[col.value], item.duration, i18n.resolvedLanguage)
                              ) : (
                                col.type === 'bookingStatus' ? (
                                  item[col.value] === 'unpaid' ? 
                                    <span className={`px-2 py-1 rounded bg-red-400 text-white`}><MdOutlineCreditCardOff className='inline-block mb-[2px]' /></span> : 
                                    item[col.value] === 'paid' ? 
                                      <span className={`px-2 py-1 rounded bg-green-500 text-white`}><MdCreditCard className='inline-block mb-[2px]' /></span> : 
                                        <span className={`px-2 py-1 rounded bg-yellow-500 text-white`}><IoTicket className='inline-block mb-[2px]' /></span>
                                ) : (
                                  col.type === 'boolean' ? (
                                    item[col.value] ? <span className='text-green-500 text-xl'><IoMdCheckbox /></span> : <span className='text-gray-500 text-xl'><MdCheckBoxOutlineBlank /></span>
                                  ) : (
                                    col.translate ? (
                                      t(item[col.value])
                                    ) : (
                                      <span dangerouslySetInnerHTML={{ __html: highlightText(item[col.value], (col.highlight && searchText) || '') }}></span>
                                    )
                                  )
                                )
                              )
                            )
                          )
                        )}
                      </td>
                    )
                  })}
                 {editable && <td className="px-2 w-[110px] text-end"> {deleteItem && <button className="text-red-400 focus:outline-none focus:ring-4 focus:ring-red-300 focus:bg-red-400 focus:text-white focus:dark:text-shark-950 hover:bg-red-400 hover:text-white hover:dark:text-shark-950 rounded p-2" onClick={() => deleteItem(item)}><FaRegTrashAlt /></button>}</td>}
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
}

export default Table