import { useState } from "react"
import { useTranslation } from "react-i18next"
import { User } from "../types"
import Form from "./global/Form"
import FormHeader from "./global/FormHeader"
import FormInput from "./global/FormInput"
import FormSelect from "./global/FormSelect"
import { MdDarkMode } from "react-icons/md"
import { IoMdSunny } from "react-icons/io"

interface Props {
  user:User
  cancel: () => void
  submit: (value: User) => void
}

function UserForm({user, cancel, submit}:Props) {

  const { t } = useTranslation(['user', 'global'])
  const [userState, setUserState] = useState<User>(user)

  const roleOptions = [
    { label:t('editorRole'), value:'editor' },
    { label:t('adminRole'), value:'admin' },
  ]

  const langOptions = [
    { label: t('ca',{ns:'global'}), value: 'ca' },
    { label: t('es',{ns:'global'}), value: 'es' },
    { label: t('en',{ns:'global'}), value: 'en' },
  ]

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    submit(userState)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setUserState({
      ...userState,
      [e.target.name]: e.target.value
    })
  }

  const setDarkMode = (value:boolean) => {
    if(value) document.documentElement.classList.add("dark")
    else document.documentElement.classList.remove("dark")
    setUserState({...userState, darkMode:value})
  }

  return (
    <>
      <FormHeader title={user.id ? t('edit_user') : t('new_user')}/>
      <Form handleSubmit={handleSubmit} back={cancel}>
        <div className="sm:col-span-2 flex gap-4">
          <button 
            className={`border border-indigo-500 ${!userState.darkMode ? 'text-white bg-indigo-500' : 'text-indigo-500'} focus:outline-none focus:ring-4 focus:ring-indigo-300 focus:bg-indigo-500 focus:text-white hover:bg-indigo-500 hover:text-white rounded py-1 px-4`}
            type="button"
            onClick={() => setDarkMode(false)}
          ><IoMdSunny className='inline-block mb-[2px] mr-[6px]' />{t('light_mode')}</button>
          <button 
            className={`border border-purple-500 ${userState.darkMode ? 'text-white bg-purple-500' : 'text-purple-500'} focus:outline-none focus:ring-4 focus:ring-purple-300 focus:bg-purple-500 focus:text-white hover:bg-purple-500 hover:text-white rounded py-1 px-4`}
            type="button"
            onClick={() => setDarkMode(true)}
          ><MdDarkMode className='inline-block mb-[2px] mr-[6px]' />{t('dark_mode')}</button>
        </div>
        <FormInput 
          label={t('name') || ''} 
          type="text" 
          name="name" 
          ariaLable={t('name') || ''} 
          value={userState.name} 
          onChange={handleChange} 
          required />
        <FormInput 
          label={t('email') || ''} 
          type="email" 
          name="email" 
          ariaLable={t('email') || ''} 
          value={userState.email} 
          onChange={handleChange} 
          required
          disabled={user.id ? true : false} />
        <FormSelect 
          label={t('role') || ''}
          name="role"
          ariaLable={t('role') || ''}
          options={roleOptions}
          defaultValue={user.role}
          handler={handleChange}
        />
        <FormSelect 
          label={t('language') || ''}
          name="lang"
          ariaLable={t('language') || ''}
          options={langOptions}
          defaultValue={user.lang}
          handler={handleChange}
        />
      </Form>
    </>
  )
}

export default UserForm