import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Table from "../components/global/Table";
import { useFirestoreCollection, Filter } from "../hooks/useFirestoreCollection";
import { Receipt } from "../types";
import { useNavigate } from "react-router-dom";
import { getDateTime } from "../services/utils";
import ApproveModal from "../components/global/ApproveModal";
import { useAlert } from "../context/AlertContext";
import ReceiptForm from "../components/ReceiptForm";

interface UseFirestoreCollectionType {
  items: Array<Receipt>
  addItem: (item: Receipt) => Promise<void>
  updateItem: (item: Receipt) => Promise<void>
  deleteItem: (item: Receipt) => Promise<void>
  sort: {
    field: string
    order: number
  }
  setSort: (value: {field:string, order:number}) => void
  selectedFilters: Array<Filter>
  setSelectedFilters: (value: Array<Filter>) => void
  setLimitItems: (value: any) => void
}

function Receipts() {
  
  const { t, i18n } = useTranslation(['receipt', 'global'])
  let navigate = useNavigate()
  const { receiptId } = useParams()
  const limitItems = 40

  const { addAlert } = useAlert()
  const {items, addItem, updateItem, deleteItem, sort, setSort, selectedFilters, setSelectedFilters, setLimitItems} = useFirestoreCollection("receipts", {field:'dateTime', order:-1}, limitItems) as UseFirestoreCollectionType
  const [deleteReceipt, setDeleteReceipt] = useState<Receipt|undefined>(undefined)
  const [currentReceipt, setCurrentReceipt] = useState<Receipt|undefined>(undefined)

  const scrollBottom = (value:boolean) => {
    if(value) setLimitItems((prevState:number) => prevState + limitItems)
  }
  
  const columns = [
    {label:t('client',{ns:'global'}), value:'clientName'},
    {label:t('price',{ns:'global'}), value:'price', type:'price', class:'w-[90px]'},
    {label:t('conceptType'), value:'conceptType', translate:true, class:'w-[120px]', hideMobile: true},
    {label:t('date',{ns:'global'}), value:'dateTime', type:'dateTime', sortable:true, class:'w-[180px]', hideMobile: true}
  ]

  const filters = [
    {field: 'conceptType', options: [
      {label:t('allConcepts'), value:'allItems'},
      {label:t('booking',{ns:'global'}), value:'booking'},
      {label:t('bonus',{ns:'global'}), value:'bonus'},
      {label:t('equipment_rental',{ns:'global'}), value:'equipment_rental'},
      {label:t('other',{ns:'global'}), value:'other'},
    ]}
  ]

  const newReceipt:Receipt = {
    clientId: '',
    clientName: '',
    price: 0,
    payMethod: "card",
    dateTime: new Date().toISOString(),
    conceptType: "equipment_rental",
    concept: ""
  }

  const upsertHandler = async (receipt:Receipt) => {
    try {
      if (receipt.id) {
        await updateItem(receipt)
        addAlert({type:"success", title:t('receipt_updated'), message:receipt.clientName+' ('+receipt.price+'€) ' || ''})
      } else {
        await addItem(receipt)
        addAlert({type:"success", title:t('receipt_created'), message:receipt.clientName+' ('+receipt.price+'€) ' || ''})
      }
      navigate(-1)
    } catch (error) {
      console.error(error)
      addAlert({type:"error", title:t('genericFirebaseError',{ns:'global'}), message:''})
    }
  }

  const deleteHandler = async (value:boolean) => {
    try {
      if(value && deleteReceipt) {
        await deleteItem(deleteReceipt)
        addAlert({type:"success", title:t('receipt_deleted'), message:deleteReceipt?.clientName+' ('+deleteReceipt.price+'€) ' || ''})
      }
      setDeleteReceipt(undefined)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if(items.length < 1 && receiptId && receiptId !== 'new') return
    if(receiptId) setCurrentReceipt(items.filter(i => i.id === receiptId)[0] || newReceipt) // GET BOOKING FROM FIREBASE!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    else if(currentReceipt) setCurrentReceipt(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[receiptId, items])
  
  return (
    <>
      {deleteReceipt && <ApproveModal title={t('delete_Receipt')} question={t('delete_Receipt_question')} item={deleteReceipt.clientName+' ('+deleteReceipt.price+'€) '+getDateTime(deleteReceipt.dateTime, i18n.resolvedLanguage)} approve={deleteHandler}/>}
      {currentReceipt ?
        <ReceiptForm 
          receipt={currentReceipt} 
          submit={upsertHandler}
          cancel={() => navigate(-1)}
        /> : 
        <Table 
          columns={columns} 
          items={items} 
          sort={sort} 
          setSort={setSort} 
          filters={filters}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          editable={true}
          editItem={(item) => navigate(item.id)}
          newItem={() => navigate('new')}
          deleteItem={setDeleteReceipt}
          setScrollBottom={scrollBottom}
        />
      }
    </>
  )
}
export default Receipts