import { FaTimes } from 'react-icons/fa'
import Select from './Select'
import { MdFilterAlt } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

interface Props {
  setShowFilters: (value:boolean) => void
  filters: Array<{
    field: string
    options: Array<{
      label:string
      value:string
    }>
  }>
  selectedFilters: Array<{
    field:string
    value:string
  }>
  setSelectedFilters: (value:Array<SelectedFilter>) => void
}

interface SelectedFilter {
  field:string
  value:string
}

function TableFilters({setShowFilters, filters, selectedFilters, setSelectedFilters}: Props) {

  const { t } = useTranslation(['global'])

  const getSelectedFilter = (field:string) => {
    return selectedFilters.filter(filter => filter.field === field)[0]?.value || undefined
  }

  const handler = (field:string, value:string) => {
    const filters = selectedFilters.filter((filter) => filter.field !== field)
    if(value !== 'allItems') filters.push({field:field, value:value})
    setSelectedFilters(filters)
    setShowFilters(false)
  }

  return (
    <>
      <div onClick={() => setShowFilters(false)} className="fixed z-[1] inset-0 bg-gray-600 bg-opacity-90"></div>
      <div className="fixed z-10 left-5 sm:left-20 top-14 bg-white dark:bg-shark-900 rounded">
        <div className="bg-corporate-500 text-white dark:text-shark-950 py-2 px-4 flex justify-between rounded-t">
          <div className="flex text-xl">
            <MdFilterAlt size={24} className='mt-[2px] mr-3'/>
            {t('filter')}
          </div>
          <button className="focus:outline-none focus:ring-4 focus:ring-corporate-300 focus:bg-corporate-600 focus:text-corporate-200 hover:bg-corporate-600 hover:text-corporate-200 rounded px-2" onClick={() => setShowFilters(false)} aria-label='close modal'><FaTimes /></button>
        </div>
        <div className="flex flex-col items-center w-[250px] p-4">
          {filters.map(filter => {
            return (
              <div key={filter.field} className='mb-4 w-full'>
                <Select options={filter.options} defaultValue={getSelectedFilter(filter.field)} handler={(value) => handler(filter.field, value)} />
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default TableFilters